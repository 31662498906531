import moment from 'moment';
import React, { useState } from 'react';
import { Button, Card, Col, Form, FormGroup, Row, Tab } from "react-bootstrap";
import Select from "react-select";
import { colourStyles, despOptions } from "../../utils";
import TimeLine from "./TimeLine";

const TicketDetails = (props) => {

  const {
    TicketData: {
      college_code,
      college_name,
      grivenceText,
      mobile,
      raised_by,
      ticket_date,
      assigned_date,
      closed_date,
    },
    ticketHistory,
    ChatNstatus,
    onSelectAssigndropdown,
    descriptionOption,
    selectdescriptiondropdown,
    Status,
    descriptionSelects,
    selectedOption,
    options,
    fetchTicketHistory,
  } = props;
  const ticketDate = moment(ticket_date, "DD-MM-YYYY HH:mm:ss A ")?.format("DD-MM-YYYY");
  const closedDate = moment(closed_date, "MM-DD-YYYY HH:mm:ss A ")?.format("DD-MM-YYYY");
  const [commentText, setCommentText] = useState("");
  const userRole = JSON.parse(sessionStorage.getItem("authkey"))?.userRole ?? undefined;

  let filteredData = options?.filter(item => item.label != props?.TicketData?.assignee)


  return (
    <Row className=" row-sm ">
      <Col lg={12} md={12}>
        <div className="custom-card main-content-body-profile">
          <Tab.Content>
            <Tab.Pane eventKey="Profile">
              <div
                className="main-content-body tab-pane  active"
                id="about"
              >
                <Card>
                  <Row className="p-2">
                    <Col sm={12}>
                      <Card className=" custom-card">
                        <Card className="custom-card customs-cards">
                          <Card.Body className=" d-md-flex bg-white">
                            <div className="">
                              <span className="profile-image pos-relative">
                                <div class="avatar avatar-xxl d-none d-sm-flex bg-pink rounded-circle mt-3">
                                  {raised_by[0]}
                                  {raised_by[1].toUpperCase()}
                                </div>
                              </span>
                            </div>
                            <div className="my-md-auto mt-4 prof-details">
                              <h4
                                className="font-weight-semibold ms-md-4 ms-0 mb-1 pb-0"
                                style={{ fontSize: "xx-large" }}
                              >
                                {raised_by}
                              </h4>
                              <p className="text-muted ms-md-4 ms-0 mb-2">
                                <span>
                                  <i className="fa fa-phone me-2"></i>
                                </span>
                                <span className="font-weight-semibold me-2">
                                  Phone:
                                </span>
                                <span>+250-{mobile}</span>
                              </p>
                              <p className="text-muted ms-md-4 ms-0 mb-2">
                                <span>
                                  <i className="fe fe-hash me-2"></i>
                                </span>
                                <span className="font-weight-semibold me-2">
                                  School Code:
                                </span>
                                <span>{college_code}</span>
                              </p>
                              <p className="text-muted ms-md-4 ms-0 mb-2">
                                <span>
                                  <i className="mdi mdi-bank me-2"></i>
                                </span>
                                <span className="font-weight-semibold me-2">
                                  School Name:
                                </span>
                                <span>{college_name}</span>
                              </p>
                            </div>
                          </Card.Body>
                        </Card>
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="Description">
              <div
                className="main-content-body tab-pane border-top-0"
                id="edit"
              >
                <Card>
                  <Card.Body className=" border-0">
                    <Form className="form-horizontal ms-4">
                      <FormGroup className="form-group ">
                        <Row >
                          <Col >
                            <Form.Label className="form-label">
                              <span className="font-weight-semibold me-1">
                                Issues Description :
                              </span>
                              <span>{grivenceText}</span>
                            </Form.Label>
                          </Col>
                        </Row>
                      </FormGroup>
                      <FormGroup className="form-group ">
                        <Row >
                          <Col >
                            <Form.Label className="form-label">
                              <span className="font-weight-semibold me-1">
                                Created Date :
                              </span>
                              <span>{ticketDate}</span>
                            </Form.Label>
                          </Col>
                        </Row>
                      </FormGroup>
                      {userRole === "ADMIN" && (
                        <FormGroup className="form-group ">
                          {
                            Status === "Assign"?
                            <></>
                            :
                          <Row >
                            <Col >
                              <Form.Label className="form-label">
                                <div className=" SlectBox">
                                  {
                                    Status === "Closed" ?
                                      <Form.Control
                                        className="form-control"
                                        placeholder={Status}
                                        readOnly
                                        type="text"
                                      />
                                      :
                                      <Select
                                        class="form-select" aria-label="Default select example"
                                        value={descriptionOption}
                                        onChange={selectdescriptiondropdown}
                                        options={despOptions}
                                        placeholder="Select"
                                        styles={colourStyles}
                                      />
                                  }
                                </div>
                              </Form.Label>
                            </Col>
                          </Row>
                          }
                        </FormGroup>



                      )}
                      {descriptionSelects &&
                        <FormGroup className="form-group ">
                          <Row >
                            <Col >
                              <Form.Label className="form-label">
                                <div className=" SlectBox">

                                  <Select
                                    class="form-select" aria-label="Default select example"
                                    value={selectedOption}
                                    onChange={onSelectAssigndropdown}
                                    options={filteredData}
                                    placeholder="Select"
                                    styles={colourStyles}
                                    isOptionDisabled={(options) => options?.label === props?.TicketData?.assignee}
                                  />

                                </div>
                              </Form.Label>
                            </Col>
                          </Row>
                        </FormGroup>
                      }
                      {userRole === "ADMIN" && (
                        <FormGroup className="form-group ">
                          {
                            Status === "Assign"?
                            <></>
                            :
                          <Row >
                            <Col >
                              <Form.Label
                                htmlFor="message-text"
                                className="col-form-label"
                              >
                                Comment Box* :
                              </Form.Label>
                              <textarea
                                className="form-control mb-2"
                                id="message-text"
                                placeholder="Enter comment"
                                value={commentText}
                                onChange={(event) =>
                                  setCommentText(event.target.value)
                                }
                              ></textarea>
                              <Button
                                variant="primary"
                                disabled={commentText === ""}
                                onClick={(e) => {
                                  ChatNstatus(parseInt(descriptionOption.value), commentText)
                                  setCommentText("")
                                }
                                }
                              >
                                {"Submit"}
                              </Button>
                            </Col>
                          </Row>
                          }
                        </FormGroup>
                      )}
                      <FormGroup className="form-group ">
                        <Row >
                          <Col >
                            <Form.Label className="form-label">
                              Closed Date :{" "}
                              {closedDate === "Invalid date"
                                ? "NA"
                                : closedDate}
                            </Form.Label>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Form>
                  </Card.Body>
                </Card>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="History">
              <div
                className="main-content-body tab-pane  border-0"
                id="settings"
              >
                <Card>
                  <Card.Body
                    className=" border-0 ms-4"
                    data-select2-id="12"
                  >
                    <h5 className="form-label">TimeLine</h5>
                    <div
                      style={{ height: "80%", overflow: "hidden" }}
                    >
                      <TimeLine ticketHistory={ticketHistory} />
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Col>
    </Row>
  )
}

export default TicketDetails
