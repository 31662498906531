import toast from "react-hot-toast";
import { put, takeLatest, call } from "redux-saga/effects";
import Axios from "../../services/axios";
import { ACTION_GET_DEPARTMENT_DETAILS_REQ, ACTION_GET_DASHBOARD_DEPARTMENT_DETAILS_REQ } from "../SagaActions/SagaActionTypes";
import { getAPI, postAPI } from "./ApiMethods";
import { actionReqResStatusLoaderSagaAction } from '../SagaActions/DepartmentSagaAction';

function* getDeparmentDetailsReqSaga(action) {
  try {
    const resp = yield call(getAPI, process.env.REACT_APP_BASE_URL + "/api/home/departments");
    if (resp && resp?.length > 0) {
      action?.payload?.callback(resp);

    } else {
      toast.error(resp?.respdesc);

    }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
      yield put(actionReqResStatusLoaderSagaAction(false));

    } else {
      toast.error(err.message);
      yield put(actionReqResStatusLoaderSagaAction(false));

    }
  } finally {

  }
}

function* getDashboardDeparmentDetailsReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  try {
    const resp = yield call(postAPI, process.env.REACT_APP_BASE_URL + "/api/dashboard/homepage");
    if (resp && resp?.length > 0) {
      action?.payload?.callback(resp);
      yield put(actionReqResStatusLoaderSagaAction(false));

    } else {
      // toast.error(resp?.respdesc);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    if (err.response) {
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
      yield put(actionReqResStatusLoaderSagaAction(false));
    } else {
      toast.error(err.message);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }
}

export default function* DepartmentWatcherSaga() {
  yield takeLatest(
    ACTION_GET_DEPARTMENT_DETAILS_REQ,
    getDeparmentDetailsReqSaga
  );
  yield takeLatest(
    ACTION_GET_DASHBOARD_DEPARTMENT_DETAILS_REQ,
    getDashboardDeparmentDetailsReqSaga
  );
}
