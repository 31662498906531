import toast from "react-hot-toast";
import Axios from "../../services/axios";

export const getAPI = (url) => {
  return Axios.get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response) {
        toast.error(
          err?.response?.data?.errors?.length &&
            err?.response?.data?.errors[0]?.message
        );
        // return err?.response?.data?.errors[0]?.message
      } else {
        toast.error(err.message);
      }
    });
};

export const postAPI = (url, body, apikey) => {
  let headers
  if (apikey) { 
    headers= {
      'apikey': apikey
    }
  }
  return Axios.post(url, body,headers)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response ? err.response : err
  //     if (err.response) {
  //       toast.error(
  //         err?.response?.data?.errors?.length &&
  //           err?.response?.data?.errors[0]?.message
  //       );
  //       // return err?.response?.data?.errors[0]?.message
  //     } else {
  //       toast.error(err.message);
  //     }

    });
};
