import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Row } from "react-bootstrap";
import Select from "react-select";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import { useDispatch } from "react-redux";
import Moment from "moment";
import { extendMoment } from 'moment-range';
import { holidayAdd, holidayDelete, holidayUpdate, holidayList, stateholidayAdd, stateholidayUpdate, stateholidayDelete, stateholidayList } from '../Store/SagaActions/HolidaySagaActions'
import AreUSureModal from "../components/Modals/Duplicates";
import AddViewHolidayModal from "../feature/Holiday/AddViewHolidayModal";
import { toast } from "react-hot-toast";

export const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: isFocused ? "#999999" : null,
            color: "#333333",
        };
    },
};

var HolidayCopy
const SingleInfo = () => {
    const enrollAttdLS = localStorage.getItem("enrollAttdLS");
    const studentStaffLS = localStorage.getItem("studentStaffLS");
    const moment = extendMoment(Moment);
    let eventGuid = 0;
    const initialstate1 = {
        calendarEvents: [
            {
                title: "Atlanta Monster",
                start: new Date("2022-10-11 00:00"),
                id: "1001",
            }
        ],
        events: [
            {
                title: "My Event 6",
                id: "6",
                bg: "bg-danger",
                border: "border-danger",
            },
        ],
    };
    const userRole = JSON.parse(sessionStorage.getItem("authkey"))?.userRole ?? undefined;
    const [state] = useState(initialstate1);
    const [percentageDataOfEnrolledStu, setpercentageDataOfEnrolledStu] = useState([]);
    const [CalenderData, SetCalenderData] = useState([]);
    const dispatch = useDispatch();
    const [HolidayList, setHolidayList] = useState([])
    const [selectedHoliday, setSelectedHoliday] = useState("0")
    const [selectHolidayType, setSelectHolidayType] = useState([
        {
            holiId: "1",
            holiName: "Public Holiday",
        },
        {
            holiId:"2",
            holiName: "Optional Holiday"
        }
    ])
    function OnSelectHoliday(e) {
        const { value } = e.target
        setSelectedHoliday(value)
    }
    useEffect(() => {
        if (HolidayCopy === undefined) return
        if (selectedHoliday === "0") setHolidayList(HolidayCopy)
        else setHolidayList(HolidayCopy.filter(i => i.holiday_type === selectedHoliday))
    }, [selectedHoliday])

    const Show = (type) => {
        switch (type) {
            case "1":
                return "#800080"
            case 1:
                return "linear-gradient(119.95deg, #800080 -0.43%, #FFC0CB 99.72%)"
            case "2":
                return "#FC4A1A"
            case 2:
                return "linear-gradient(119.95deg, #FC4A1A -0.38%, #F7B733 99.77%)"
            case "3":
                return "#3F2B96"
            case 3:
                return "linear-gradient(212.93deg,  #3F2B96 0%,#A8C0FF 95.57%)"
            case "4":
                return "#fcf8e3"
            case "5":
                return "#9dc8da"
            default:
                return "white"
        }
    }

    function holidayListResp(data) {
        HolidayCopy = data.sort(function (a, b) {
            return new Date(moment(a.fromdate, "DD-MM-YYYY")) - new Date(moment(b.fromdate, "DD-MM-YYYY"));
        })
        setHolidayList(data)
        const mappeddata = data.map((i, idx) => ({
            start: moment(i.fromdate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            end: moment(i.todate, 'DD-MM-YYYY').add(1, "day").format('YYYY-MM-DD'),
            title: i.hname,
            id: `${idx}`,
            backgroundColor: Show(i.holiday_type),
            display: "auto",
            color: "#ffffff",
        }))
        SetCalenderData(mappeddata)
    }

    const CallHolidayList = () => {
        const model = {};
        if (userRole === "STATE") {
            dispatch(
                stateholidayList({
                    model,
                    callback: holidayListResp,
                })
            );
        }
        else {
            dispatch(
                holidayList({
                    model,
                    callback: holidayListResp,
                })
            );
        }

    }
    useEffect(() => {
        CallHolidayList()
    }, [])

    function checkDateRange(start, end, st, ed) {
        if (moment(start).isSame(ed)) {
            start = moment(start).subtract(1, "day")
        }
        const range1 = moment?.range(start, end)
        const range2 = moment?.range(st, ed)
        return (range1.overlaps(range2) || moment(start).isSame(st) || moment(start).isSame(end)) === true ? 0 : 1
    }

    const [selectedCustomHoliday, setselectedCustomHoliday] = useState([])
    const [isView, setisView] = useState(false)
    function handleDateSelect(selectInfo, _sidebarclick) {
        let { start, end } = selectInfo
        if (_sidebarclick !== undefined) {
            start = moment(_sidebarclick?.fromdate, "DD-MM-YYYY").toDate()
            end = moment(_sidebarclick?.todate, "DD-MM-YYYY").add('1', 'day').toDate()
        }
        let holidayexists, holidayCustomList = []
        for (let index = 0; index < HolidayCopy.length; index++) {
            const st = moment(HolidayCopy[index].fromdate, "DD-MM-YYYY").toDate()
            const ed = moment(HolidayCopy[index].todate, "DD-MM-YYYY").toDate()
            const eventCheck = checkDateRange(start, end, st, ed, "start")
            const HolidayCheck = checkDateRange(start, end, st, ed, "end")
            if (eventCheck === 0 || HolidayCheck === 0) {
                holidayexists = true
                holidayCustomList = [HolidayCopy[index], ...holidayCustomList]
            }
        }
        const unique = Array.from(new Set(holidayCustomList.map(JSON.stringify))).map(JSON.parse)
        setselectedCustomHoliday(unique)
        if (holidayexists === undefined) setisView(false)
        if (unique?.length > 0) setisView(true)
        end.setDate(end.getDate() - 1)
        setFormDeatils({ fromdate: start, todate: end, })
        if (holidayexists === undefined) {
            setAreuSurePopup(true)
            setHolidayEditDelete("Add")
        }
        else {
            setAddviewHolidayPopup(true)
            setHolidayEditDelete("View")
        }
    };

    const [AreuSurePopup, setAreuSurePopup] = useState(false);
    const [AddviewHolidayPopup, setAddviewHolidayPopup] = useState(false);
    const [FormDeatils, setFormDeatils] = useState({
        fromdate: new Date(),
        todate: new Date(),
    })
    const handleClick = () => {
        if (holidayAddEditDelete === "Delete") {
            if (remarkDetails === "") {
                toast.error("Please Fill in the remarks")
            }
            else handleAddviewHoliday()
        } else {
            setAreuSurePopup(false)
            if(userRole==="STATE"){
                setFormDeatils({
                    ...FormDeatils,
                    holiday_type:(FormDeatils?.holiday_type && FormDeatils?.holiday_type) ? FormDeatils?.holiday_type : "1"
                })
            }
            setAddviewHolidayPopup(true)
        }
    }
    const handleAddviewHoliday = (e) => {
        let model = FormDeatils
        switch (userRole) {
            case "STATE":
                switch (holidayAddEditDelete) {
                    case "Add":
                        e.preventDefault()
                        dispatch(
                            stateholidayAdd({
                                model,
                                callback: (data) => {
                                    const { responsecode, responsedesc } = data
                                    if (responsecode === "200") {
                                        setAddviewHolidayPopup(false)
                                        setSelectedHoliday("0")
                                        toast.success(responsedesc)
                                    }
                                    else {
                                        toast.error(responsedesc)
                                    }
                                    CallHolidayList()
                                },
                            })
                        );
                        return
                    case "Edit":
                        e.preventDefault()
                        dispatch(
                            stateholidayUpdate({
                                model,
                                callback: (data) => {
                                    const { responsecode, responsedesc } = data
                                    if (responsecode === "200") {
                                        setAddviewHolidayPopup(false)
                                        setSelectedHoliday("0")
                                        toast.success(responsedesc)
                                    }
                                    else {
                                        toast.error(responsedesc)
                                    }
                                    CallHolidayList()
                                },
                            })
                        );
                        return
                    case "Delete":
                        model = {remarks:remarkDetails, hid:FormDeatils.hid}
                        dispatch(
                            stateholidayDelete({
                                model,
                                callback: (data) => {
                                    const { responsecode, responsedesc } = data
                                    if (responsecode === "200") {
                                        setAreuSurePopup(false)
                                        setSelectedHoliday("0")
                                        toast.success(responsedesc)
                                    }
                                    else {
                                        toast.error(responsedesc)
                                        setAreuSurePopup(false)
                                    }
                                    setRemarkDetails(undefined)
                                    CallHolidayList()
                                },
                            })
                        );
                        return
                    default:
                        toast("Something unexpected happened Please try again")
                }
                return
            case "PRINCIPAL":
                switch (holidayAddEditDelete) {
                    case "Add":
                        e.preventDefault()
                        dispatch(
                            holidayAdd({
                                model,
                                callback: (data) => {
                                    const { responsecode, responsedesc } = data
                                    if (responsecode === "200") {
                                        setAddviewHolidayPopup(false)
                                        setSelectedHoliday("0")
                                        toast.success(responsedesc)
                                    }
                                    else {
                                        toast.error(responsedesc)
                                    }
                                    CallHolidayList()
                                },
                            })
                        );
                        return
                    case "Edit":
                        e.preventDefault()
                        dispatch(
                            holidayUpdate({
                                model,
                                callback: (data) => {
                                    const { responsecode, responsedesc } = data
                                    if (responsecode === "200") {
                                        setAddviewHolidayPopup(false)
                                        setSelectedHoliday("0")
                                        toast.success(responsedesc)
                                    }
                                    else {
                                        toast.error(responsedesc)
                                    }
                                    CallHolidayList()
                                },
                            })
                        );
                        return
                    case "Delete":
                        model = {remarks:remarkDetails, hid:FormDeatils.hid}
                        dispatch(
                            holidayDelete({
                                model,
                                callback: (data) => {
                                    const { responsecode, responsedesc } = data
                                    if (responsecode === "200") {
                                        setAreuSurePopup(false)
                                        setSelectedHoliday("0")
                                        toast.success(responsedesc)
                                    }
                                    else {
                                        toast.error(responsedesc)
                                        setAreuSurePopup(false)
                                    }
                                    setRemarkDetails(undefined)
                                    CallHolidayList()
                                },
                            })
                        );
                        return
                    default:
                        toast("Something unexpected happened Please try again")
                }
        }

    }

    const [holidayAddEditDelete, setHolidayEditDelete] = useState("Add")
    const handleChange = (e, _editDelete) => {
        if (e === "Delete") {
            setRemarkDetails("")
        }
        else {
            setRemarkDetails(undefined)
        }
        if (_editDelete) {

            const { hid, hname, fromdate, todate, remarks,holiday_type } = _editDelete
            setHolidayEditDelete(e)
            setisView(false)
            setAreuSurePopup(true)
            setFormDeatils({ hid, hname, fromdate: moment(fromdate, "DD-MM-YYYY").toDate(), todate: moment(todate, "DD-MM-YYYY").toDate(), remarks,holiday_type })
        }
        else {
            if (isView === true || isView === false) {
                let { name, value } = e.target
                setFormDeatils({ ...FormDeatils, [name]: value })
            }
        }
    }

    const [remarkDetails, setRemarkDetails] = useState()
    function eventClick(data) {
        const { start, end } = data?.event
        let e = { start, end, eventClick: "true" }
        if (loginRole.includes(userRole)) handleDateSelect(e)
    }
    function dateClick(data) {
        const { date } = data
        let e = { start: date, end: moment(date).add(1, "day").toDate(), dateClick: "true" }
        if (loginRole.includes(userRole)) handleDateSelect(e)
    }

    const loginRole = ["PRINCIPAL", "STATE"]


    return (
        <div
            style={{
                marginTop: "1rem"

            }}
        >
            <AreUSureModal
                showPopup={AreuSurePopup}
                setShowPopup={setAreuSurePopup}
                customText={`Are You Sure you want to ${holidayAddEditDelete} Holiday ?`}
                handleClick={handleClick}
                style={{
                    zIndex: "9991"
                }}
                remarkDetails={remarkDetails}
                setRemarkDetails={setRemarkDetails}
            />
            <AddViewHolidayModal
                showPopup={AddviewHolidayPopup}
                setShowPopup={setAddviewHolidayPopup}
                handleClick={handleAddviewHoliday}
                FormDeatils={FormDeatils}
                handleChange={handleChange}
                selectHolidayType={selectHolidayType}
                style={{
                    zIndex: "9991"
                }}
                isView={isView}
                selectedCustomHoliday={selectedCustomHoliday.sort(function (a, b) {
                    return b.holiday_type - a.holiday_type
                })}
                holidayAddEditDelete={holidayAddEditDelete}
                Show={Show}
            />
            <Card>
                <Card.Body
                >
                    <Row>
                        <Col>
                            <Card.Header className=" border-bottom-0 d-flex justify-content-between">
                                <div>
                                    <h3 className="card-title">Holiday</h3>
                                </div>
                                {(loginRole.includes(userRole)) && <Button
                                    style={{ padding: ".25rem 1.5rem" }}
                                    onClick={() => {
                                        setHolidayEditDelete("Add")                                        
                                        setFormDeatils({
                                            fromdate: new Date(),
                                            todate: new Date(),
                                        })
                                        setAreuSurePopup(true)

                                    }}
                                >Add</Button>}
                            </Card.Header>
                            <div className="d-lg-flex "
                                style={{ maxHeight: "72vh", overflow: "scroll" }}
                            >
                                <div id="calendar2" className="bg-card col-lg-8" >
                                    <FullCalendar
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                        headerToolbar={{
                                            left: "prev",
                                            center: "title",
                                            right: "next",
                                        }}
                                        initialView="dayGridMonth"
                                        selectable={loginRole.includes(userRole) ? true : false}
                                        selectMirror={loginRole.includes(userRole) ? true : false}
                                        droppable={false}
                                        themeSystem="bootstrap5"
                                        theme
                                        weekends={state.weekendsVisible}
                                        initialEvents={percentageDataOfEnrolledStu}
                                        select={handleDateSelect}
                                        eventClick={eventClick}
                                        dateClick={dateClick}
                                        events={CalenderData}
                                        longPressDelay={0}
                                        fixedWeekCount={false}
                                    />

                                </div>
                                <div className="bg-card col-lg-3 w-100">
                                    <FormGroup className="text-start form-group " >
                                        <Form.Select id="disabledSelect" className=""
                                            value={selectedHoliday} onChange={OnSelectHoliday} >
                                            <option value={0}>Holiday - All</option>
                                            <option value={1}>Holiday - Mandatory</option>
                                            <option value={2}>Holiday - Optional</option>
                                            <option value={3}>Holiday - Custom</option>
                                        </Form.Select>
                                        {/* <Form.Select id="disabledSelect" className="mt-2"
                                            value={selectedHoliday} onChange={OnSelectHoliday} >
                                            <option value={0} disabled>2023</option>
                                        </Form.Select> */}
                                    </FormGroup>

                                    {HolidayList.length > 0 && <>
                                        {HolidayList.map((i, idx) => <Card key={idx}
                                            onClick={userRole === "PRINCIPAL" ? (e) => handleDateSelect(e, i) : () => { }}
                                        >
                                            <div className="d-flex tx-8"
                                            >
                                                <div
                                                    style={{
                                                        background: Show(parseInt(i.holiday_type)),
                                                        borderRadius: "8px 0px 0px 8px",
                                                        color: "white",
                                                        minWidth: "30%",
                                                        textAlign: "center",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <div><h5 style={{ fontWeight: "lighter" }}>{moment(i.fromdate, "DD-MM-YYYY").format("MMM")}</h5></div>
                                                    <div><h6>{moment(i.fromdate, "DD-MM-YYYY").format("DD")}</h6></div>
                                                </div>
                                                <div className="p-2">
                                                    <h5 >{i.hname}</h5>
                                                    <div><h6 style={{ fontWeight: "300" }}>{moment(i.fromdate, "DD-MM-YYYY").format("ddd yyyy")}</h6></div>
                                                </div>
                                            </div>
                                        </Card>)}
                                    </>}
                                </div>
                            </div>
                            <div className="d-flex py-2 px-4">

                                {[["Today", "4"], ["Selected", "5"], ["Mandatory holidays", 1], ["Optional holidays", 2], ["Custom holidays", 3]].map((i, idx) => <div
                                    key={idx}
                                    className="d-flex justify-content-center align-content-center"
                                >
                                    <div className="mx-2 "
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            background: Show(i[1]),
                                            borderRadius: "4px",
                                        }}
                                    ></div> <div className="">{i[0]}</div>

                                </div>)}
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
};

export default SingleInfo;
