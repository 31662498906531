import moment from "moment";
import React, { useState } from "react";
import { Button, Card, Form, FormGroup, Modal, ModalFooter } from "react-bootstrap";
import "./modal.css"
/***
 * @param {boolean} showPopup- enable/disable popup
 * @param {text} infoMessage - placehoder after are u sure
 * @param {boolean} approvereject-helps decide color of titleText  
 * @function handleClick(approvereject,data)- call back function 
 * @param {text:optional} remarkDeatils -helps giving reason for decision
 */
const AddViewHolidayModal = (props) => {
    const { showPopup,
        setShowPopup,
        handleClick,
        FormDeatils,
        handleChange,
        isView,
        selectedCustomHoliday,
        holidayAddEditDelete,
        selectHolidayType
    } = props
    const userRole = JSON.parse(sessionStorage.getItem("authkey"))?.userRole ?? undefined;
    return (
        <Modal
            size={"lg center"}
            show={showPopup}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <h6 className="modal-title">{`${holidayAddEditDelete}  Holiday`}</h6>
                <Button
                    variant=""
                    aria-label="Close"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    type="button"
                    onClick={() => setShowPopup(false)}
                >
                    <span aria-hidden="true">&times;</span>
                </Button>
            </Modal.Header>
            <Modal.Body scrollable="true">

                {(holidayAddEditDelete === "Add" || holidayAddEditDelete === "Edit") ? <>
                    <Form onSubmit={handleClick}
                        className="p-4"
                    >
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Holiday Title"
                                onChange={handleChange}
                                name="hname"
                                value={FormDeatils?.hname}
                                required

                            />
                        </Form.Group>
                        {userRole === "STATE" ?
                            <FormGroup className="text-start form-group">
                                <Form.Label>Holiday Type*</Form.Label>
                                <Form.Select id="disabledSelect"
                                    name={"holiday_type"}
                                    onChange={handleChange}
                                    required
                                >
                                    <option disabled value={0}>Select Holiday Type</option>
                                    {
                                        selectHolidayType?.map((item, idx) =>
                                            <option key={idx} value={item?.holiId}>{item?.holiName}</option>
                                        )
                                    }
                                </Form.Select>
                            </FormGroup>
                            :
                            <></>
                        }

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>From</Form.Label>
                            <Form.Control
                                type="date"
                                placeholder={FormDeatils?.fromdate}
                                name="fromdate"
                                value={moment(FormDeatils?.fromdate).format('YYYY-MM-DD')}
                                onChange={handleChange}
                                required

                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>To</Form.Label>
                            <Form.Control
                                type="date"
                                name="todate"
                                value={moment(FormDeatils?.todate).format('YYYY-MM-DD')}
                                onChange={handleChange}
                                required

                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder={"Please Enter Description"}
                                value={FormDeatils?.description}
                                name="description"
                                rows={4}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                        <button className="btn btn-danger m-2" type="button"
                            onClick={() => setShowPopup(false)}
                        >Close</button>
                    </Form>
                </> :

                    <Card className=" modalbg"
                        style={{
                            maxHeight: `220px`,
                            overflow: "scroll",
                        }}
                    >
                        {selectedCustomHoliday.length > 0 && <>
                            {selectedCustomHoliday.map((i, idx) => <Card
                                key={idx}
                                className="d-flex m-2"

                            >
                                <Card.Header
                                    className=" border-bottom-0 ">
                                    <h3 className="card-title mb-0 ">{i.hname}</h3>
                                </Card.Header>
                                <Card.Body className=" border-bottom-0 "
                                    style={{ padding: "2px 16px" }}
                                >
                                    <div >
                                        <h5 className="card-title mb-1 ">  <span className="tx-12 mb-0 text-muted">From: </span>  {i.fromdate}</h5>
                                        <h5 className="card-title mb-1 ">  <span className="tx-12 mb-0 text-muted">to: </span>  {i.todate}</h5>
                                        <div className="text-muted tx-12">Description</div>
                                        <div >{i?.description?.length > 0 ? i?.description : "NA"}</div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div >updated on :<span className="tx-12 mb-0 text-muted">{i.datecreated} </span></div>
                                        {(i?.holiday_type === "3" || userRole==="STATE") && <div className="d-flex">

                                            <button className="btn btn-outline-warning m-2" type="button" onClick={() => {
                                                handleChange("Edit", i)
                                                setShowPopup(false)

                                            }} >Edit</button>
                                            <button className="btn btn-outline-danger m-2" type="button" onClick={() => {
                                                handleChange("Delete", i)
                                                setShowPopup(false)
                                            }} >Delete</button>
                                        </div>}
                                    </div>

                                </Card.Body>

                            </Card>)}

                        </>
                        }

                    </Card>
                }
            </Modal.Body>

            {holidayAddEditDelete === "View" && <Modal.Footer
                className="p-0 m-0"
            >
                <button type="button"
                    onClick={() => setShowPopup(false)}
                    style={{
                        width: 'fit-content ',
                    }}
                    className="btn btn-danger m-2 "
                >Close</button>
            </Modal.Footer>}

        </Modal>
    )
}
export default AddViewHolidayModal;