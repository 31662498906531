import {toast} from "react-hot-toast";
import { put, takeLatest, call } from "redux-saga/effects";
import {
  ACTION_POST_GRIVENCE_PIECHART_REQ,
  ACTION_POST_GRIVENCE_ALL_REQ,
  ACTION_POST_GRIVENCE_TICKET_HISTORY_REQ,
  ACTION_POST_GRIVENCE_TICKET_ASSIGN_DROPDOWN_REQ,
  ACTION_POST_GRIVENCE_TICKET_ASSIGN_REQ,
  ACTION_POST_GRIVENCE_INPROGRESS_REQ,
  ACTION_POST_GRIVENCE_PENDING_REQ,
  ACTION_POST_GRIVENCE_CLOSED_ASSIGN_REQ,
  ACTION_POST_GRIVENCE_ASSIGN_TO_ME_REQ,
  ACTION_POST_GRIVENCE_ASSIGN_PRINCIPAL_REQ
} from "../SagaActions/SagaActionTypes";
import { getAPI, postAPI } from "./ApiMethods";
import { actionReqResStatusLoaderSagaAction } from "../SagaActions/DepartmentSagaAction";


function* postGrivencePieChartReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/grievances/status-summary",
    action?.payload?.model
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
        );
      }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* postGrivenceAllReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/grievances/all",
    action?.payload?.model
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* postGrivenceTicketHistoryReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/grievances/ticket-history",
    action?.payload?.model
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* postgrivenceticketassigndropdownReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/grievances/users",
    action?.payload?.model
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}
function* postgrivenceticketassignReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/grievances/assign",
    action?.payload?.model
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    toast.success("Success");
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* postgrivenceticketinprogressReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/grievances/ticket-notes",
    action?.payload?.model
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    toast.success("Success");
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* postgrivenceticketpendingReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/grievances/ticket-pending",
    action?.payload?.model
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    toast.success("Success");
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* postgrivenceticketclosedReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/grievances/ticket-close",
    action?.payload?.model
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    toast.success("Success");
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}


function* postgrivenceticketassignedtomeReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/grievances/grievances-assigned",
    action?.payload?.model
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* postgrivenceticketprincipal(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
    "/api/grievances/grievances-college",
    action?.payload?.model
  );
  try {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    if (err.response) {
      action?.payload?.callback([]);
      toast.error(
        err?.response?.data?.errors?.length &&
        err?.response?.data?.errors[0]?.message
      );
    }
  } finally {
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}


export default function* GrivenceWatcherSaga() {
  yield takeLatest(ACTION_POST_GRIVENCE_PIECHART_REQ, postGrivencePieChartReqSaga);
  yield takeLatest(ACTION_POST_GRIVENCE_ALL_REQ, postGrivenceAllReqSaga);
  yield takeLatest(ACTION_POST_GRIVENCE_TICKET_HISTORY_REQ, postGrivenceTicketHistoryReqSaga);
  yield takeLatest(ACTION_POST_GRIVENCE_TICKET_ASSIGN_DROPDOWN_REQ, postgrivenceticketassigndropdownReqSaga);
  yield takeLatest(ACTION_POST_GRIVENCE_TICKET_ASSIGN_REQ, postgrivenceticketassignReqSaga);
  yield takeLatest(ACTION_POST_GRIVENCE_INPROGRESS_REQ, postgrivenceticketinprogressReqSaga);
  yield takeLatest(ACTION_POST_GRIVENCE_PENDING_REQ, postgrivenceticketpendingReqSaga);
  yield takeLatest(ACTION_POST_GRIVENCE_CLOSED_ASSIGN_REQ, postgrivenceticketclosedReqSaga);
  yield takeLatest(ACTION_POST_GRIVENCE_ASSIGN_TO_ME_REQ, postgrivenceticketassignedtomeReqSaga);
  yield takeLatest(ACTION_POST_GRIVENCE_ASSIGN_PRINCIPAL_REQ, postgrivenceticketprincipal);
}

