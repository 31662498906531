import{
     ACTION_POST_ATTENDEE_MANGEMENT_DISTRICT_LIST_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_MANDAL_LIST_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_ADD_ORG_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_DEPARTMENT_COLLEGES_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_ADD_CLASS_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_ADD_STAFF_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_STAFF_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_COLLEGE_CLASS_LIST_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_ADD_STUDENT_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_STUDENT_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_ADD_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_DESIGNATION_MASTER_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_SINGLE_STUDENT_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_SINGLE_STAFF_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_ADD_UPDATE_DESIGNATION_REQ,
     ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_GEO_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_STUDENT_LIST_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_STAFF_LIST_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_ATTENDEE_STATUS_REQ ,
     ACTION_POST_ATTENDEE_MANGEMENT_ROLE_LIST_REQ,
        
} from "./SagaActionTypes";

export const attendeeDistrictList=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_DISTRICT_LIST_REQ,
        payload:payload,
    }
}
export const attendeeMandalList=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_MANDAL_LIST_REQ,
        payload:payload,
    }
}
export const attendeeAddOrg=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_ADD_ORG_REQ,
        payload:payload,
    }
}
export const attendeeDeptCollege=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_DEPARTMENT_COLLEGES_REQ,
        payload:payload,
    }
}
export const attendeeAddClass=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_ADD_CLASS_REQ,
        payload:payload,
    }
}
export const attendeeAddStaff=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_ADD_STAFF_REQ,
        payload:payload,
    }
}
export const attendeeUpdateStaff=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_STAFF_REQ,
        payload:payload,
    }
}
export const attendeeCollegeClassList=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_COLLEGE_CLASS_LIST_REQ,
        payload:payload,
    }
}
export const attendeeAddStudent=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_ADD_STUDENT_REQ,
        payload:payload,
    }
}
export const attendeeUpdateStudent=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_STUDENT_REQ,
        payload:payload,
    }
}
export const attendeeAdd=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_ADD_REQ,
        payload:payload,
    }
}
export const attendeeDesignationMaster=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_DESIGNATION_MASTER_REQ,
        payload:payload,
    }
}
export const attendeeSingleStudent=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_SINGLE_STUDENT_REQ,
        payload:payload,
    }
}
export const attendeeSingleStaff=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_SINGLE_STAFF_REQ,
        payload:payload,
    }
}
export const attendeeAddUpdateDesignation=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_ADD_UPDATE_DESIGNATION_REQ,
        payload:payload,
    }
}
export const attendeeUpdateGeo=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_GEO_REQ,
        payload:payload,
    }
}
export const attendeeStudentList=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_STUDENT_LIST_REQ,
        payload:payload,
    }
}
export const attendeeStaffList=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_STAFF_LIST_REQ,
        payload:payload,
    }
}
export const attendeeUpdateStatus=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_ATTENDEE_STATUS_REQ,
        payload:payload,
    }
}
export const attendeeRoleList=(payload)=>{
    return{
        type: ACTION_POST_ATTENDEE_MANGEMENT_ROLE_LIST_REQ,
        payload:payload,
    }
}
