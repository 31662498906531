
import React, { useState } from 'react'
import {
    attendanceReportAction,
    attendanceVerificationReportAction,
    attendancesearchAction,
    hierarchywiseStaffListAction,
} from '../../Store/SagaActions/TableDetailSagaAction';
import { useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Breadcrumb } from 'react-bootstrap';
import { useEffect } from 'react';
import moment from 'moment';

const AttendeeSummaryPresenter = () => {
    const dispatch = useDispatch();
    const [nextDisabled, setnextDisabled] = useState("")
    const [AttendanceSearchOptions, setAttendanceSearchOptions] = useState([])

    const [breadcrumData, setbreadcrumData] = useState([
        { value: JSON.parse(sessionStorage.getItem("authkey"))?.orgid, label: 'Home' }])

    const [HideNavigationMenu, setHideNavigationMenu] = useState(false)
    const [staffListData, setStaffListData] = useState({})
    const [selectedStaff, setSelectedStaff] = useState({})
    const [attendanceSummaryFullData, setAttendanceSummaryFullData] = useState({})
    const [attendancePersonalData, setAttendancePersonalData] = useState({})
    const [attendanceSummaryData, setAttendanceSummaryData] = useState({})
    const [attendanceSummaryTableData, setAttendanceSummaryTableData] = useState({})
    const [selectedFromDate, setSelectedFromDate] = useState(new Date())
    const [selectedToDate, setSelectedToDate] = useState(new Date())

    const AttendanceReportResp = (data, id) => {
        setStaffListData({})
        if (data[0]?.isclass === "1") {
            const temp = [...breadcrumData]
            temp.splice(breadcrumData?.length, 1)
            setbreadcrumData([...temp])
            toast.error("No further option available")
            return
        }
        else {
            if (data?.length === 0) {
                // setHideNavigationMenu(true)
                // setnextDisabled(breadcrumData[breadcrumData?.length - 1])
                // callingstaffList(id.value)

                const temp = [...breadcrumData]
                temp.splice(breadcrumData?.length, 1)
                setbreadcrumData([...temp])
                toast.error("No further option available")
                return
            }

            const datas = data.map((i, idx) => {
                const { orgid: value, orgname: label, orgcode } = i
                return ({ value, label, orgcode })
            })
            setAttendanceSearchOptions(datas)
        }
    }

    const onChildNavigation = (id) => {
        setAttendanceSummaryTableData({})
        if (id === undefined) {
            const orgname = JSON.parse(sessionStorage.getItem("authkey"))?.orgname ?? undefined;
            const userRole = JSON.parse(sessionStorage.getItem("authkey"))?.userRole ?? undefined;
            const orgid = JSON.parse(sessionStorage.getItem("authkey"))?.orgid ?? undefined;
            if (userRole === "PRINCIPAL") {
                setAttendanceSearchOptions([{ value: orgid, label: orgname, orgcode: orgid, isclass: "0" }])
                setbreadcrumData([breadcrumData[0]])
            }
            else {
                let model = { orgid }
                dispatch(attendanceReportAction({ model, callback: AttendanceReportResp }))
                setbreadcrumData([breadcrumData[0]])
            }
        }
        else {
            setnextDisabled({})
            setbreadcrumData([...breadcrumData, id])
            let model = { orgid: id?.value }
            dispatch(attendanceReportAction({ model, callback: (data) => AttendanceReportResp(data, id) }))
        }
    }

    const onSelectOrg = (id) => {
        callingstaffList(id.value);
        setnextDisabled(id)
    }

    const HandleBreadCrumb = (idx) => {
        setHideNavigationMenu(false)
        setAttendanceSummaryTableData({})
        if (idx) onChildNavigation(breadcrumData[idx])
        else onChildNavigation()
        const temp = [...breadcrumData]
        temp.splice(idx + 1, Infinity)
        setbreadcrumData([...temp])
    }

    const Breadcrumbs = () => {
        return <Breadcrumb className="breadcrumb-style mg-b-0 d-flex">
            {breadcrumData?.map((i, idx) => {
                if (idx === breadcrumData?.length - 1) {
                    return <Breadcrumb.Item href="#"
                        active>
                        {i?.label}
                    </Breadcrumb.Item>
                }
                return (<Breadcrumb.Item href="#"
                    onClick={() => HandleBreadCrumb(idx)}
                >
                    {i?.label}
                </Breadcrumb.Item>)
            }
            )}
        </Breadcrumb>
    }

    const onSelectFromDate = async (e) => {
        setSelectedFromDate(e);
    };
    const onSelectToDate = async (e) => {
        setSelectedToDate(e)
    };

    const callingAttendaceSummaryData = () => {
        let breadcrumbOrgid = breadcrumData[breadcrumData.length - 1].value

        if (Object.keys(selectedStaff).length === 0) {
            toast.error("Please select your data from above table")
            return
        }
        let model = {
            "aid": selectedStaff?.["Att Id"], //"521302",
            "orgid": breadcrumbOrgid,
            "fromdate": moment(selectedFromDate)?.format("YYYY-MM-DD"),
            "todate": moment(selectedToDate)?.format("YYYY-MM-DD")
        }
        dispatch(attendanceVerificationReportAction({
            model,
            callback: (data) => {
                if (data?.status || data?.length === 0) {
                    toast.error("No Data Found")
                    return
                }
                setAttendanceSummaryFullData(data[0])
                const { attendancedetails, summarydetails, personaldetails } = data[0]
                setAttendancePersonalData(personaldetails[0])
                setAttendanceSummaryData(summarydetails[0])
                const datamapped = attendancedetails.map((i, idx) => {
                    const { date, intime, outtime, intime_Latitude, intime_Longitude, outtime_Latitude,
                        outtime_Longitude, duration, isholiday, isleave, isabsent, leave, holiday } = i
                    const temp = {
                        "date": date,
                        "intime": intime,
                        "outtime": outtime,
                        "intime Latitude": intime_Latitude,
                        "intime Longitude": intime_Longitude,
                        "outtime Latitude": outtime_Latitude,
                        "outtime Longitude": outtime_Longitude,
                        "duration": duration,
                        "holiday ": isholiday === "1" ? "Yes" : "No",
                        "holiday name": holiday === "" ? "_" : holiday,
                        "leave ": isleave === "1" ? "Yes" : "No",
                        "leave type": leave === "" ? "_" : leave,
                        "absent": isabsent === "1" ? "Yes" : "No",

                    }
                    return temp;
                })
                setAttendanceSummaryTableData(datamapped)
            }
        }))
    }
    const handleClick = (e, data, row, header, col) => {
        setSelectedStaff(data)
    }

    useEffect(() => {
        onChildNavigation()
    }, [])


    const callingstaffList = (orgid) => {
        let model = {
            orgid: orgid
        }
        dispatch(hierarchywiseStaffListAction({
            model,
            callback: (data) => {
                if (data?.details.length === 0) {
                    toast.error("No data found")
                    return
                }
                const datamapped = data?.details.map((i, idx) => ({
                    "Att Id": i?.aid,
                    "Org Id": i?.orgid,
                    "Att Name": i?.attdname,
                }))
                setSelectedStaff({})
                setStaffListData(datamapped)
                setAttendanceSummaryTableData({})
            }
        }))
    }


    return (
        {
            AttendanceSearchOptions,
            breadcrumData,
            nextDisabled,
            onSelectOrg,
            onChildNavigation,
            Breadcrumbs,
            attendanceSummaryTableData,
            attendanceSummaryData,
            attendancePersonalData,
            selectedToDate,
            selectedStaff,
            selectedFromDate,
            onSelectToDate,
            callingAttendaceSummaryData,
            handleClick,
            staffListData,
            onSelectFromDate,
            HideNavigationMenu

        }
    )
}

export default AttendeeSummaryPresenter
