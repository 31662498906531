import React, { useEffect } from 'react'
import { Breadcrumb, Card, Col, Row, Table } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { attendanceViewAction } from '../../Store/SagaActions/TableDetailSagaAction'
import { useState } from 'react'
import moment from 'moment'
import { toast } from 'react-hot-toast'

const AttendReportPresenter = (updateShowPopup) => {
  const dispatch = useDispatch()
  const [attendanceData, setAttendanceData] = useState({})
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [breadcrumbData, setBreadCrumbData] = useState([
    { value: JSON.parse(sessionStorage.getItem("authkey"))?.orgid, label: 'Home' }])
  const [attendanceTableData, setAttendanceTableData] = useState([])
  const [attendanceTableFullData, setAttendanceTableFullData] = useState([])
 
  let homepageOrgid = JSON.parse(sessionStorage.getItem("authkey")).orgid;
  let userRole = JSON.parse(sessionStorage.getItem("authkey")).userRole;

  const onSelectDate = async (e) => {
    setSelectedDate(e);
  };
  const tableData = (data, header) => {
    let datamapped = data.map(({ hasdrilldown, orgid, orgname, total,enrolled,present,absent,punches,unique_punches,leave_count,
      latelogin_count, earlylogout_count}) => {
      let temp = { orgid, [header]: orgname, 
        total,
        enrolled,
        present,
        absent,
        punches,
        "unique punches":unique_punches,
        "leave count":leave_count,
        "latelogin count":latelogin_count,
        "earlylogout count":earlylogout_count
       }
      return temp
    })
    setAttendanceTableData(datamapped)
  }
  
  const orgHeadername = (type) => {
    if(userRole==="PRINCIPAL"){
      switch (type) {
        case 1:
          return "School Name"
        case 2:
          return "School Name"
        default:
          return 'Organisation Name'
      }
    }
    else{
      switch (type) {
        case 1:
          return "District Name"
        case 2:
          return "Sector Name"
        case 3:
          return "School Name"
        case 4:
          return "Class Name"
        default:
          return 'Organisation Name'
      }
    }
  }

  const [headertext, setheadertext] = useState("")

  useEffect(() => {
    const data =orgHeadername(breadcrumbData?.length)
    setheadertext(data)
    tableData(attendanceTableFullData,data )
  }, [attendanceTableFullData])


  const callingAttendanceData = (orgid, drilldown) => {
    if (drilldown === "0") {
     
     // updateShowPopup(true,orgid,moment(selectedDate)?.format("YYYY-MM-DD"));
      toast.error("No further option available")
      const temp = [...breadcrumbData]
      temp.splice(breadcrumbData?.length, 1)
      setBreadCrumbData([...temp])
      return
    }
    let model = {
      "orgid": orgid === undefined ? homepageOrgid : orgid,
      "rptdate": moment(selectedDate)?.format("YYYY-MM-DD")
    }
    dispatch(
      attendanceViewAction({
        model,
        callback: async (data) => {
          if (data?.status || data?.details?.length === 0) {
            const temp = [...breadcrumbData]
            temp.splice(breadcrumbData?.length, 1)
            setBreadCrumbData([...temp])
            return
          }
          setAttendanceData(data)
          const { details } = data
          setAttendanceTableFullData(details)
        },
      })
    );
  }
  const handleClick = (e, data, row, header, col) => {
    const temp = attendanceTableFullData[row].hasdrilldown
    setBreadCrumbData([...breadcrumbData, { value: data?.orgid, label: data[headertext] }])
    callingAttendanceData(data?.orgid, temp)
  }


  const HandleBreadCrumb = (idx) => {
    callingAttendanceData(breadcrumbData[idx]?.value)
    const temp = [...breadcrumbData]
    temp.splice(idx + 1, Infinity)
    setBreadCrumbData([...temp])
  }

  const Breadcrumbs = () => {
    return <Breadcrumb className="breadcrumb-style mg-b-0 d-flex">
      {breadcrumbData?.map((i, idx) => {
        if (idx === breadcrumbData?.length - 1) {
          return <Breadcrumb.Item href="#"
            active>
            {i?.label}
          </Breadcrumb.Item>
        }
        return (<Breadcrumb.Item href="#"
          onClick={() => HandleBreadCrumb(idx)}
        >
          {i?.label}
        </Breadcrumb.Item>)
      }
      )}
    </Breadcrumb>
  }

  useEffect(() => {
    callingAttendanceData()
  }, [])

  useEffect(() => {
    callingAttendanceData(breadcrumbData[breadcrumbData.length - 1]?.value)
  }, [selectedDate])

  return {
    Breadcrumbs,
    attendanceData,
    attendanceTableData,
    handleClick,
    selectedDate,
    onSelectDate
    
  }
}

export default AttendReportPresenter
