import React, { useEffect, useState } from "react";
import { Button, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from "react-table";
import TableHeader from "./TableHeader";
import Tablefooter from "./Tablefooter";
import { badgecolors } from "./data";
import "./report.css";
import { SmsSvg, WhatsAppSvg } from "./svgIcons";
import NoMore from "../NoMore";
import moment from "moment";

var districtCopyData = [];
const ReportTable = (props) => {
  const {
    selectFilteroption,
    onSelectFilteropion,
    filterOptions,
    clickableColumns,
    StyledColumns,
    badgeBG,
    type,
    assignedTome,
    onSelectAssignedToMe,
    selectedDate,
    onSelectDate,
    openModal,
    onSync,
    Breadcrumbs,
    buttonText,
    openMessageModal,
    hideTableData,
    Holiday,
  } = props;
  const [districtFinlaData, setDistrictFinalData] = useState([]);
  const [col, setcol] = useState([]);
  const apiStatus = useSelector(state => state.HomeReducer.apiStatus)

  useEffect(() => {
    setDistrictFinalData(props.tableData);
    districtCopyData = props.tableData;
    const COLUMNS = Object.keys(props.tableData[0]).map((i) => {
      return {
        Header: i,
        accessor: i,
        className: " ",
      };
    });
    setcol(COLUMNS);
    // Filter(selectFilteroption)
  }, [props]);

  const tableInstance = useTable(
    {
      columns: col,
      data: districtFinlaData,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps, // table props from react-table
    headerGroups, // headerGroups, if your table has groupings
    getTableBodyProps, // table body props from react-table
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    state,
    setFilter,
    setGlobalFilter,
    page, // use page or rows
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    rows,
  } = tableInstance;
  const { globalFilter, pageIndex, pageSize } = state;
  useEffect(() => {
    if (filterOptions === undefined) {
      setDistrictFinalData(districtCopyData)
      return
    }
    var data
    var datakey = selectFilteroption?.short
    if (datakey == "ALL") {
      data = districtCopyData
    }  else if ( datakey == "P") {
      // console.log(districtCopyData.map((item,idx)=>item.status))
      data = districtCopyData.filter(ele => ele.status == "AA" || ele.status == "P"|| ele.status == "HDOD" || ele.status=="OD");
    }else if ( datakey == "OL") {
      data = districtCopyData.filter(ele => ele.status == "CL" || ele.status == "SL" );
    } 
     else if (datakey == "Enrolled") {
      data = districtCopyData.filter(ele => ele.status != "NE");
    }
    else {
      data = districtCopyData.filter(ele => {
        return (ele.status == datakey)
      });
    }
    setDistrictFinalData(data)
  }, [apiStatus, props])

  const ToolTipTrigger = (text, Icon) => {
    return (

      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip id="button-tooltip">{text}</Tooltip>}
      >
        {Icon}
      </OverlayTrigger>
    );

  };

  return (
    <Row>
      <Col sm={12} className="col-12">
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between">
              <h4 className="card-title">{props?.title}</h4>
              <div>

                {openMessageModal &&
                  <>{
                    <SmsSvg openMessageModal={openMessageModal} tool={ToolTipTrigger} />
                  }
                    {
                      ToolTipTrigger(
                        "Send WhatsApp Notification",
                        <WhatsAppSvg openMessageModal={openMessageModal} />
                      )
                    }
                  </>
                }
                {openModal && <Button className="m-2" onClick={() => openModal("add")}>
                  {buttonText}
                </Button>}
              </div>
            </div>
            <TableHeader
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              Row={Row}
              Col={Col}
              pageSize={pageSize}
              setPageSize={setPageSize}
              selectFilteroption={selectFilteroption}
              onSelectFilteropion={onSelectFilteropion}
              filterOptions={filterOptions}
              setFilter={setFilter}
              type={type}
              assignedTome={assignedTome}
              onSelectAssignedToMe={onSelectAssignedToMe}
              selectedDate={selectedDate}
              onSelectDate={onSelectDate}
              openModal={openModal}
              onSync={onSync}

            />
          </Card.Header>
          <Card.Body className="pt-0 example1-table">
            {
              (Holiday && Holiday!=="")?
              <NoMore
              holiday={Holiday}
              selectedday={moment(selectedDate)?.format("YYYY-MM-DD")}
              >
              </NoMore>
              :
              hideTableData ?
              <div className="nodata">No Data Found</div>
              :
                <div className="table-responsive">
                  <div id="custom-responsive-table">
                    <table
                      {...getTableProps()}
                      className="table table-hover text-nowrap mb-0 tablebshadow"
                      id="table-to-xls"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, i) => {
                              return (
                                <th
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  )}
                                  className={column.className}
                                  style={{ padding: ".75rem" }}
                                >
                                  {
                                    <span className="tabletitle">
                                      {column.Header}
                                    </span>
                                  }
                                  <span>
                                    {column.isSorted &&
                                      column?.Header !== "Action" ? (
                                      column.isSortedDesc ? (
                                        <i className="fa fa-angle-down"></i>
                                      ) : (
                                        <i className="fa fa-angle-up"></i>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </th>
                              );
                            })}
                          </tr>
                        ))}
                      </thead>

                      {rows.length != 0 && (
                        <tbody {...getTableBodyProps()}>
                          {page.map((row) => {
                            prepareRow(row);

                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell, i) => {

                                  return (
                                    <td
                                      data-title={`${cell?.column?.Header}`}
                                      {...cell.getCellProps()}
                                      style={{ cursor: (clickableColumns && clickableColumns[0].includes(i)) ? "pointer" : "" }}
                                      onClick={(clickableColumns && clickableColumns[0].includes(i)) ? (e) => {
                                        clickableColumns[1](e, row?.original, row?.id, cell?.column?.Header, i)
                                      } : () => { }}
                                    >
                                      <div className={(StyledColumns && StyledColumns[0].includes(i)) ? `${StyledColumns[1][StyledColumns[0].indexOf(i)]} ${badgeBG ? badgecolors(cell.value) : "text"}` : ``}
                                      >
                                        {cell.render("Cell")}
                                      </div>

                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </table>
                    {rows.length == 0 && (
                      <div className="nodata">No Data Found</div>
                    )}
                  </div>

                  <Tablefooter
                    districtFinlaData={districtFinlaData}
                    selectedTableType={props.selectedTableType}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    rows={rows}
                    pageSize={pageSize}
                    pageCount={pageCount}
                    gotoPage={gotoPage}
                    canPreviousPage={canPreviousPage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    fileName={props.fileName}
                    type={props.type}
                  />

                </div>

            }
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ReportTable;
