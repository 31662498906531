import toast from "react-hot-toast";
import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import Axios from '../../services/axios'
import { actionReqResStatusLoaderSagaAction } from "../SagaActions/DepartmentSagaAction";
import { ACTION_POST_STAFFATTAINDENCE_REQ, ACTION_POST_STUDENTATTAINDENCE_REQ,ACTOIN_POST_COLLEGE_DETAILS_REQ } from "../SagaActions/SagaActionTypes";


const studentReq = (model) => {
  const URL = "dashboardapi/api/dashboard/studentattendance";
  return Axios.post(URL, model).then((res) => res?.data).catch(E => E)
};

function* studentdetailsSaga(action) {
  const resp = yield call(studentReq, action?.payload?.model);
  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
    }
  } catch (err) {
    action?.payload?.callback(resp);
  }

}

const staffReq = (model) => {
  const URL = "dashboardapi/api/dashboard/staffattendance";
  return Axios.post(URL, model).then((res) => res?.data).catch(E => E)
};

function* staffDetailsSagaReq(action) {
  const resp = yield call(staffReq, action?.payload?.model);

  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
    }

  } catch (err) {
    action?.payload?.callback(resp);
  }

}

const collegeReq = (model) => {
  const URL =process.env.REACT_APP_BASE_URL+ "/api/dashboard/departmentwisecollegedetails";
  return Axios.post(URL, model).then((res) => res?.data).catch(E => E)
};

function* collegeDetailsSagaReq(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(collegeReq, action?.payload?.model);

  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(actionReqResStatusLoaderSagaAction(false));

    }

  } catch (err) {
    action?.payload?.callback(resp);
    yield put(actionReqResStatusLoaderSagaAction(false));

  }

}


export default function* AttaindenceWatcherSaga() {
  yield takeEvery(ACTION_POST_STUDENTATTAINDENCE_REQ, studentdetailsSaga);
  yield takeEvery(ACTION_POST_STAFFATTAINDENCE_REQ, staffDetailsSagaReq);
  yield takeEvery(ACTOIN_POST_COLLEGE_DETAILS_REQ, collegeDetailsSagaReq);
}
