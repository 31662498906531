import toast from "react-hot-toast";
import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import Axios from "../../services/axios";
import {
  ACTION_POST_CHANGEPASSWORD_REQ,
  ACTION_POST_LOGIN_REQ,
  ACTION_POST_LOGOUT_REQ,
  ACTION_POST_GENERATE_OTP_REQ,
  ACTION_POST_VALIDATE_OTP_REQ,
  ACTION_POST_RESET_PASSWORD_REQ,
} from "../SagaActions/SagaActionTypes";
import { postAPI } from "./ApiMethods";
import { actionReqResStatusLoaderSagaAction } from "../SagaActions/DepartmentSagaAction";

function* loginReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL + "/api/home/authenticate",
    action?.payload?.model
  );
  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    action?.payload?.callback(resp);
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* logoutReq(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL + "/api/home/revoke-token",
    action?.payload?.model
  );

  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    action?.payload?.callback(resp);
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* changePasswordReq(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL + "/api/home/changepassword",
    action?.payload?.model
  );

  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  }
}

function* genrateOTPReq(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL + "/api/home/generateotp",
    action?.payload?.model
  );

  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  }
}
function* validateOTPReq(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL + "/api/home/validateotp",
    action?.payload?.model
  );

  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  }
}
function* resetPasswordReq(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL + "/api/home/resetpassword",
    action?.payload?.model
  );

  try {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback(resp);
  }
}

export default function* AuthWatcherSaga() {
  yield takeEvery(ACTION_POST_LOGIN_REQ, loginReqSaga);
  yield takeEvery(ACTION_POST_LOGOUT_REQ, logoutReq);
  yield takeEvery(ACTION_POST_CHANGEPASSWORD_REQ, changePasswordReq);
  yield takeEvery(ACTION_POST_GENERATE_OTP_REQ, genrateOTPReq);
  yield takeEvery(ACTION_POST_VALIDATE_OTP_REQ, validateOTPReq);
  yield takeEvery(ACTION_POST_RESET_PASSWORD_REQ, resetPasswordReq);
}
