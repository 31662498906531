import React, { useState } from "react";
import { Modal } from "react-bootstrap";
/***
 * @param {boolean} showPopup- enable/disable popup
 * @param {boolean} setshowPopup- enable/disable popup
*  @param {text} titleText - placehoder after are u sure
 * @param {boolean} approvereject-helps decide color of titleText  
 * @function handleClick(approvereject,data)- call back function 
 * @param {text:optional} remarkDeatils -helps giving reason for decision
 * @param {text:optional} defaultText -replaces Question above yes no button
 */
const AreUSureModal = (props) => {
    const { showPopup,
        setShowPopup,
        handleClick,
        approvereject,
        data,
        customText,
        titleText,
        setRemarkDetails,
        remarkDetails } = props

    
    return (
        <Modal
            size={remarkDetails !== undefined ? "lg center" : "sm center"}
            show={showPopup}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div
                className="p-4">
                <div className="table ">
                    {customText ? <>
                        <h3 className="text-center ">
                            {customText} 
                        </h3>
                    </> : <h3 className="text-center "> Are you sure
                        you want to {approvereject === "0" ? <span
                        className="text-secondary fw-bold"
                    >{titleText}</span> :

                        <span
                            className="text-primary fw-bold"
                        >
                            {titleText}
                        </span>} ?</h3>}
                </div>
                {
                    remarkDetails !== undefined ?
                        <div>
                            
                            <textarea
                                className="form-control"
                                placeholder="Enter Remarks"
                                as="textarea"
                                rows="4" cols="50"
                                id="remarks"
                                name={"remarks"}
                                maxLength={199}
                                value={remarkDetails}
                                onChange={(e) => setRemarkDetails(e.target.value)}
                                required
                            />
                        </div> : <></>
                }
                <div className="text-center">
                    <button className="btn btn-success-light m-2"
                        onClick={(e) => handleClick(e, approvereject, data)}
                    >Yes</button>
                    <button className="btn btn-danger-light m-2" type="button"
                        onClick={
                            () => {
                                setShowPopup(false)
                                if(setRemarkDetails)setRemarkDetails(undefined)
                            }
                        }
                    >No</button>
                </div>
            </div>
        </Modal>
    )
}
export default AreUSureModal;