import React from "react";
import { Button, Card, Form, FormGroup, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import ReportTable from "../components/Table";
import AddUpdateModal from "../feature/AttendeeMangement/Organisationmodal";

import { useDispatch } from "react-redux";

import {
  attendeeDistrictList,
  attendeeMandalList,
  attendeeAddOrg,
  attendeeDeptCollege,
} from "../Store/SagaActions/AttendeeMangementSagaActions"

import { toast } from "react-hot-toast";
import { monthlyDropdownDetailsAction } from "../Store/SagaActions/TableDetailSagaAction";

function AttendeeOrganization() {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false); // show modal

  const [deptDropDown, setDeptDropDown] = useState([])
  const [distDropDown, setDistDropDown] = useState([])
  const [mandalDropDown, setMandalDropDown] = useState([])

  const [SelectedDept, setSelectedDept] = useState("0")
  const [SelectedDist, setSelectedDist] = useState(undefined)
  const [SelectedMandal, setSelectedMandal] = useState("0")

  const [studentTableData, setstudentTableData] = useState([])

  const [rowData, setRowData] = useState({})
  const [studentFullTableData, setstudentFullTableData] = useState([])

  const [CallType, setCallType] = useState("")
  const [FormDeatils, setFormDeatils] = useState({})
  const [validated, setValidated] = useState(false); //for form validation

  function deptDropdown() {
    dispatch(
      monthlyDropdownDetailsAction({
        callback: (data) => {
          departmentDowndata(data)
        }
      })
    )
  };

  const departmentDowndata = (data) => {
    setDeptDropDown(data)
  }

  const getDistList = (deptid) => {

    let model = {
      "deptid": deptid
    }
    dispatch(
      attendeeDistrictList({
        model,
        callback: (data) => {
          distdowndata(data)
        }
      })
    )
  }

  const getMandalsList = (distorgid) => {
    let model = {
      "distorgid": distorgid
    }
    dispatch(
      attendeeMandalList({
        model,
        callback: (data) => {
          mandaldowndata(data)
        }
      })
    )
  }


  const OnSelectDept = (e) => {
    const { value } = e?.target
    if(value==SelectedDept) return
    const selectedIndex = e.target.selectedIndex 
    const label = e.target[selectedIndex].label 
    const temp=label==="TMC"?"Zone ":"Institute "
    setSelectedDept(value)
    getTableData(value,temp)
    getDistList(value)
  }

  const onSelectDist = (e) => {
    const { value } = e?.target
    setSelectedDist(value)
    getMandalsList(value)
  }

  const onSelectMandal = (e) => {
    const { value } = e?.target
    setSelectedMandal(value)

  }

  useEffect(() => {
    deptDropdown();
  }, []);

  const getTableData = (deptid,label) => {
    let model = {
      "deptid": deptid
    }
    dispatch(
      attendeeDeptCollege({
        model,
        callback: (data) => {
          studentList(data,label)
        }
      })
    )

  }
  const distdowndata = (data) => {
    setDistDropDown(data?.departmentwisedistrictsdetails)
  }

  const mandaldowndata = (data) => {
    setMandalDropDown(data?.mandalslist)
  }

  const studentList = (data,label) => {
    setstudentFullTableData(data?.studentlist)
    if (data?.departmentwisecollegeslistdetails.length === 0) toast.error("no data found")
    if (!(data?.departmentwisecollegeslistdetails)) toast.error("Something unexpected happened")

    const datamapped = (data?.departmentwisecollegeslistdetails ?? []).reverse().map((i, idx) => {
      const {
        collegename,
        collegecode,
        latitude,
        longitude
      } = i
      const temp = {
        [`Institute Name`]:collegename,
        [`Institute Code`]:collegecode,
        latitude,
        longitude
      }
      return temp;
    });

    setstudentTableData(datamapped)
  }

  const openModal = (e) => {
    setValidated(false)
    setCallType(e)
    setShowPopup(true)
  }


  const addOrganisation = (e, FormDetails) => {
    let model, temp
    if (e) {
      e.preventDefault()
    }
    const form = e.currentTarget;
    switch (CallType) {
      case "add":
        if (form.checkValidity()) {
          temp = {
            "deptid": SelectedDept,
            "mandalorgid": SelectedMandal,
          }
          model = { ...temp, ...FormDetails }
          dispatch(
            attendeeAddOrg({
              model, callback: (data) => {
                if (data?.responsecode === "200")
                  addOrg(data)
                else toast.error(data?.responsedesc + " Please try again")
              }
            })
          )
        }
        return
      default:
        return
    }
  }

  useEffect(() => {
    if (!showPopup) {
      reset();
    }
  }, [showPopup])


  const addOrg = (data) => {
    setShowPopup(false)
    getTableData(SelectedDept)
    toast.success("success")
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === "district") onSelectDist(e)
    if (name === "mandalorgid") onSelectMandal(e)
    setFormDeatils({ ...FormDeatils, [name]: value })

  }

  const reset = () => {
    setSelectedDist("0")
    setSelectedMandal("0")
  }
  return (
    <>
      <AddUpdateModal
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        deptDropDown={deptDropDown}
        SelectedDept={SelectedDept}
        SelectedDist={SelectedDist}
        mandalDropDown={mandalDropDown}
        SelectedMandal={SelectedMandal}
        distDropDown={distDropDown}
        OnSelectDept={OnSelectDept}
        onSelectDist={onSelectDist}
        addOrganisation={addOrganisation}
        onSelectMandal={onSelectMandal}
        rowData={rowData}
        handleChange={handleChange}
        FormDeatils={FormDeatils}
        validated={validated}
        reset={reset}
        setValidated={setValidated}

      />
      <Card className="col-12"
        style={{ marginTop: "1rem" }}
      >
        <Row className="m-4">
          <FormGroup className="text-start form-group col-12 col-sm-4">
            <Form.Label>Department*</Form.Label>
            <Form.Select id="disabledSelect" value={SelectedDept} onChange={OnSelectDept}
            >
              <option disabled value={0}>Select Department</option>
              {
                deptDropDown?.map((item, idx) =>
                  <option key={idx} value={item?.deptid}>{item?.deptname}</option>
                )
              }
            </Form.Select>
          </FormGroup>

        </Row>
      </Card>
      <Card>
        {
          studentTableData.length > 0 ?
            <ReportTable
              title={`Institute Management`}
              tableData={studentTableData}
              // clickableColumns={[[0, 1, 8, 9], handleClick]}
              // filterOptions={filterOptions}
              // selectFilteroption={selectFilteroption}
              // onSelectFilteropion={onSelectFilteropion}
              fileName={`${deptDropDown.find(i=>i?.deptid==SelectedDept).deptname} Institute List`}
              openModal={openModal}
              buttonText={"Add Institute"}

            /> : (studentTableData.length == 0 && SelectedDept !== "0") ? <Card
              className="text-primary "
            >
              <div className="d-flex mt-4 me-3 justify-content-end">
                <Button className="mb-2" onClick={() => openModal("add")}>ADD Institute</Button>
              </div>
            </Card> : <></>
        }

      </Card>
    </>
  );
}

export default AttendeeOrganization;
