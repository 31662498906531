import {
    ACTOIN_POST_PRINCIPAL_HOMEPAGE_DETAILS_REQ,
    ACTOIN_POST_PRINCIPAL_DEDUPLICATE_DETAILS_REQ,
    ACTOIN_POST_PRINCIPAL_DEDUPLICATE_HISTORY_DETAILS_REQ,
    ACTOIN_POST_PRINCIPAL_DEDUPLICATE_ACCEPT_REJECT_DETAILS_REQ,
  } from "./SagaActionTypes";
  
  export const principalHomepage = (payload) => {
    return {
      type: ACTOIN_POST_PRINCIPAL_HOMEPAGE_DETAILS_REQ,
      payload: payload,
    };
  };

  export const principalDeuplicate = (payload) => {
    return {
      type: ACTOIN_POST_PRINCIPAL_DEDUPLICATE_DETAILS_REQ,
      payload: payload,
    };
  };

  export const principalDeuplicateHistory = (payload) => {
    return {
      type: ACTOIN_POST_PRINCIPAL_DEDUPLICATE_HISTORY_DETAILS_REQ,
      payload: payload,
    };
  };

  export const principalDeuplicateStatus = (payload) => {
    return {
      type: ACTOIN_POST_PRINCIPAL_DEDUPLICATE_ACCEPT_REJECT_DETAILS_REQ,
      payload: payload,
    };
  };
  
  