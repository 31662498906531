import React, { useState } from "react";

import { Card, Col, Row, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { colourStyles } from "../../utils";

const TicketAssign = (props) => {
  const { selectedOption, options, ticket_ID, AssignHandler, onSelectAssigndropdown,reAssigneShow,setReAssigneShow,assignee ,status} = props
  return (
    <div>
      <div>
        <Row className="p-2">
          <Col sm={12}>
            <Card className=" custom-card">
              <Card.Header className="  d-flex custom-card-header border-bottom-0 ">
                <h5 className="card-title">Ticket Id ({ticket_ID})</h5>
              </Card.Header>
              <Card.Body>
                <h6>{(reAssigneShow===false || status==="0")?"Assign":"Reassign"}</h6>
                <div className=" SlectBox">
                  <Select
                      class="form-select" aria-label="Default select example"
                      value={selectedOption}
                      onChange={onSelectAssigndropdown}
                      options={options}
                      placeholder="Select"
                      styles={colourStyles}
                      />
                </div>
              </Card.Body>
              <div className="d-flex text-center m-auto">
              <Button className="me-3 mb-2"
                variant="primary"
                disabled={!selectedOption}
                onClick={()=>AssignHandler(reAssigneShow)}
                style={{width:"16vh"}}
              >
                {"Submit"}
              </Button>
              {reAssigneShow===true?
              <Button className="me-3 mb-2"
                variant="primary"
                onClick={()=>setReAssigneShow(false)}
                style={{width:"16vh"}}

              >
                {"Cancel"}
              </Button>:null
              }
              </div>


            </Card>
          </Col>

        </Row>
      </div>

    </div >
  );
}

TicketAssign.propTypes = {};

TicketAssign.defaultProps = {};

export default TicketAssign;
