import React from "react";
import { Card, Col, ProgressBar, Row } from "react-bootstrap";

const ReportCards = (props) => {
  return (
    <Row className="row-sm">
      <Col xl={4} lg={6} md={6} sm={12}>
        <Card>
          <Card.Body className="iconfont text-start">
            <div className="d-flex justify-content-between">
              <h4 className="card-title mb-3  card-title3">
                {props?.totalTitle}
              </h4>
            </div>
            <div className="d-flex mb-0" style={{ margin: "-5px" }}>
              <div className="">
                <h4
                  className="mb-1 font-weight-bold"
                  style={{ marginLeft: "4px" }}
                >
                  {props?.districtTotalStudentsStaff?.total ? props?.districtTotalStudentsStaff?.total : "0"}
                </h4>
              </div>
              <div
                className="card-chart ms-auto mt-0"
                style={{
                  width: "86px",
                  borderRadius: "5px",
                  boxShadow: "2px 1px 7px rgb(154 154 204 / 10%)",
                }}
              >
                <img src={props?.totalImg} />
              </div>
            </div>

            <div
              className="progress progress-sm"
              style={{ marginTop: "-18px", width: "50%" }}
            >
              <ProgressBar
                now={parseInt(100)}
                variant="teal"
                className="progress-bar bg-teal w-100"
                role="progressbar"
                
              ></ProgressBar>
            </div>
            <small className="text-muted">
              <span className="text-muted">100%</span>
            </small>
          </Card.Body>
        </Card>
      </Col>
      <Col xl={4} lg={6} md={6} sm={12}>
        <Card>
          <Card.Body className="iconfont text-start">
            <div className="d-flex justify-content-between">
              <h4 className="card-title mb-3">
                {props?.enrollPresentTitle}
              </h4>
            </div>
            <div className="d-flex mb-0" style={{ margin: "-5px" }}>
              <div className="">
                <h4
                  className=" mb-1 font-weight-bold"
                  style={{ marginLeft: "4px" }}
                >
                  {props?.districtTotalStudentsStaff?.enrolled ? props?.districtTotalStudentsStaff?.enrolled :"0"}
                
                </h4>
              </div>
              <div
                className="card-chart ms-auto mt-0"
                style={{
                  width: "86px",
                  borderRadius: "5px",
                  boxShadow: "2px 1px 7px rgb(154 154 204 / 10%)",
                }}
              >
                <img src={props?.presentEnrollImg} />
              </div>
            </div>

            <div
              className="progress progress-sm"
              style={{ marginTop: "-18px", width: "50%" }}
            >
              <ProgressBar
                now={parseInt(props?.districtTotalStudentsStaff?.percentageEnroll)}
                variant="success"
                className="progress-bar bg-success"
                role="progressbar"
                style={{
                  width: `${parseInt(
                    props?.districtTotalStudentsStaff?.percentageEnroll == undefined ? 0 : props?.districtTotalStudentsStaff?.percentageEnroll
                  )}%`,
                }}
              ></ProgressBar>
            </div>
            <small className="text-muted">
              <span className="text-muted">
              {props?.districtTotalStudentsStaff?.percentageEnroll}%
              </span>
            </small>
          </Card.Body>
        </Card>
      </Col>

      <Col xl={4} lg={6} md={6} sm={12}>
        <Card>
          <Card.Body className="iconfont text-start">
            <div className="d-flex justify-content-between">
              <h4 className="card-title mb-3 card-title2">
                {props?.notEnrolledAbsentTitle}
              </h4>
            </div>
            <div className="d-flex mb-0" style={{ margin: "-5px" }}>
              <div className="">
                <h4
                  className="mb-1   font-weight-bold"
                  style={{ marginLeft: "4px" }}
                >
                  {props?.districtTotalStudentsStaff?.notEnrolled ? props?.districtTotalStudentsStaff?.notEnrolled : "0"}
                </h4>
              </div>
              <div
                className="card-chart ms-auto mt-0"
                style={{
                  width: "86px",
                  borderRadius: "5px",
                  boxShadow: "2px 1px 7px rgb(154 154 204 / 10%)",
                }}
              >
                <img src={props?.absentNotEnrollImg} />
              </div>
            </div>

            <div
              className="progress progress-sm"
              style={{ marginTop: "-18px", width: "50%" }}
            >
              <ProgressBar
                now={parseInt(props?.districtTotalStudentsStaff?.percentageNotEnroll)}
                variant="danger"
                className="progress-bar bg-danger"
                role="progressbar"
                style={{
                  width: `${parseInt(
                    props?.districtTotalStudentsStaff?.percentageNotEnroll== undefined? 0:props?.districtTotalStudentsStaff?.percentageNotEnroll
                  )}%`,
                }}
              ></ProgressBar>
            </div>
            <small className="text-muted">
              <span className="text-muted">
                {props?.districtTotalStudentsStaff?.percentageNotEnroll}%
              </span>
            </small>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ReportCards;
