import React from 'react';
import Slider from 'react-slick';

const ImageSlider = ({ groupImages }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Slider {...settings}>
      {groupImages.map((item, index) => (
        <div key={index}>
          <div>
            <h6>{`Attendance taken by ${item.staff_name} for ${item.group_photo_organisation} at ${item.attendance_time}`}</h6>
          </div>
          <img src={`data:image/png;base64, ${item.group_photo_base64}`} alt={`Slide ${index}`} style={{ width: '100%' }} />
        </div>
      ))}
    </Slider>
  );
};

export default ImageSlider;
