
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Auth from "./Authentication/auth";
import "./index.scss";
import Loader from "./shade/Loaders/Loaders"
import { Provider } from "react-redux";
import Store from './Store/store';
import { Toaster } from 'react-hot-toast'
import LoaderComp from "./components/LoaderComp/LoaderComp";
import Tracker from '@openreplay/tracker';
import PrincipalDashboard from "./Pages/PrincipalReports";
import Duplicates from "./Pages/Duplicates/";
import DuplicatesHistory from "./Pages/Duplicates/History";
import AttendeeClass from "./Pages/AttendeeClass";
import AttendeeOrganization from "./Pages/AttendeeOrganization";
import AttendeeStaff from "./Pages/AttendeeStaff";
import AttendeeStudent from "./Pages/AttendeeStudent";
import Downloads from "./Pages/Downloads";
import Holiday from './Pages/Holiday'
import Grivences from "./Pages/Grivences";
import GrivencesHistory from "./Pages/GrivencesHistory";
import AttendeeDetails from "./Pages/AttendeeSummary";
import NotificationMangement from "./Pages/NotificationMangement";
import AttendanceReports from "./Pages/AttendanceReports";
import StaffClassAttendeeDetails from "./Pages/StaffClassAttendance";
import Leave from "./Pages/LeaveSummary";
import NotificationLogs from "./Pages/NotificationLogs";
import AuidtLogs from "./Pages/AuditLogs";
import AdminNotification from "./Pages/AdminNotification";
import { pathName } from "./utils/Path";

const tracker = new Tracker({
  projectKey: "NujWyWq1tw9PmAjZQu25",
  ingestPoint: "https://openreplay.syntizen.com/ingest",
  __DISABLE_SECURE_MODE: true

});
tracker.setMetadata('frs-dashboard');

tracker.start();
const App = React.lazy(() => import("../src/shade/layouts/App"));
const Switcherapp = React.lazy(() => import("../src/shade/layouts/Switcherapp"));

const Error404 = React.lazy(() =>
  import("./components/Pages/Authentication/404Error/404Error")
);
const Error500 = React.lazy(() =>
  import("./components/Pages/Authentication/500Error/500Error")
);
const Error501 = React.lazy(() =>
  import("./components/Pages/Authentication/501Error/501Error")
);
//Form


const CollegeReport = React.lazy(() => import("./Pages/CollegeReport"));

const Dashboard = React.lazy(() => import("./Pages/Dashboard/"));
const Reports = React.lazy(() => import("./Pages/Reports/"));
const ChangePassword = React.lazy(() => import("./Pages/ChangePassword/"));
const Privacy = React.lazy(() => import("./Pages/Privacy/"));
const Help = React.lazy(() => import("./Pages/Help/"));
const Login = React.lazy(() => import("./Pages/Login/"));

const Detailedreports = React.lazy(() => import("./Pages/DetailedReports"))
const Detailedrep = React.lazy(() => import("./Pages/DetailedReports/"))


const root = ReactDOM.createRoot(document.getElementById("root"));
  document.title = "Step Innovations Attendance"
  var link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = "assets/Images/favicon.png";
root.render(
  <>
    <Toaster />
    <Provider store={Store}>
      <React.Fragment>
        <BrowserRouter>
          <React.Suspense fallback={<Loader />}>
            {/* <React.Suspense fallback={<LoaderComp />}> */}
            <Routes>
              <Route 
              // path={`/`} element={<Auth />}
              >
                <Route index element={<Login />} />

                <Route
                  path={pathName.login}
                  element={<Login />}
                />
              </Route>
              <Route
                  path={pathName.help}
                  element={<Help />}
                />
              <Route path={`/`} element={<App />}>
                <Route
                  path={pathName.dashboard}
                  element={<Dashboard />}
                />
                <Route
                  path={pathName.helpPage}
                  element={<Help />}
                />
                <Route
                  path={pathName.pricipalDashboard}
                  element={<PrincipalDashboard />}
                />
                <Route
                  path={pathName.holiday}
                  element={<Holiday />}
                />
                 
                 <Route
                  path={pathName.grivences}
                  element={<Grivences />}
                />
                <Route
                  path={pathName.grivencesHistory}
                  element={<GrivencesHistory/>}
                />               
                
                <Route
                  path={pathName.report}
                  element={<Reports />}
                />

                <Route
                  path={pathName.changePassword}
                  element={<ChangePassword />}
                />
                <Route
                  path={pathName.download}
                  element={<Downloads />}
                />
                <Route
                  path={pathName.attendeeDetails}
                  element={<AttendeeDetails />}
                />
                <Route
                  path={pathName.leave}
                  element={<Leave />}
                />
                <Route
                  path={pathName.StaffClassAttendanceDetails}
                  element={<StaffClassAttendeeDetails />}
                />
                 <Route
                  path={pathName.attendanceview}
                  element={<AttendanceReports />}
                />
                <Route
                  path={pathName.notificationManagement}
                  element={<NotificationMangement />}
                />
                <Route
                  path={pathName.notificationLogs}
                  element={<NotificationLogs />}
                />
                <Route
                  path={pathName.notificationList}
                  element={<AdminNotification />}
                />
                <Route
                  path={pathName.auditlogs}
                  element={<AuidtLogs />}
                />
                
                <Route
                  path={pathName.privacyPolicy}
                  element={<Privacy />}
                />
                 <Route
                  path={pathName.detailedreports}
                  element={<Detailedreports />}
                />
                <Route
                  path={pathName.detail}
                  element={<Detailedrep />}
                />
                <Route
                  path={pathName.collegeReports}
                  element={<CollegeReport />}
                />
                <Route
                  path={pathName.dashboard}
                  element={<Dashboard />}
                />
                <Route
                path={pathName.duplicate}
                element={<Duplicates/>}
                  />
                  <Route
                path={pathName.duplicatehistory}
                element={<DuplicatesHistory/>}
                />
                <Route
                  path={pathName.attendeeclass}
                  element={<AttendeeClass/>}
                />
                <Route
                  path={pathName.attendeestaff}
                  element={<AttendeeStaff/>}
                />
                <Route
                  path={pathName.attendeeorganization}
                  element={<AttendeeOrganization/>}
                />
                <Route
                  path={pathName.attendeestudent}
                  element={<AttendeeStudent/>}
                />
                <Route
                  path={pathName.error404}
                  element={<Error404 />}
                />
                <Route
                  path={pathName.error500}
                  element={<Error500 />}
                />
                <Route path="*" element={<Error404 />} />
              </Route>

              <Route>
                <Route
                  path={pathName.switcherApp}
                  element={<Switcherapp />}
                />
              </Route>
              <Route></Route>
            </Routes>
          </React.Suspense>
        </BrowserRouter>
        <Toaster style={{zIndex: 9999999999}} />
      </React.Fragment>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.warn))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
