import React, { useState } from 'react'
import { GlobalFilter, Badges, badgecolors } from "./data";
import Select from "react-select";
import Calendar from '../Calendar'
import ToggleSwitch from '../Toggleswitch';
import { Button, Form } from 'react-bootstrap';
export const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#999999" : null,
      color: "#333333",
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      width: "120px",
      marginTop: "-20px"

    }
  },
  input: (styles) => {
    return {
      ...styles,
      width: "70px",
      minwidth: "60px",
      maxwidth: "120px"
    }
  },
};

const TableHeader = (props) => {
  const {
    Filter,
    assignedTome,
    onSelectAssignedToMe,
    globalFilter,
    setGlobalFilter,
    Row,
    Col,
    pageSize,
    setPageSize,
    selectFilteroption,
    onSelectFilteropion,
    filterOptions,
  } = props
  const userRole = JSON.parse(sessionStorage.getItem("authkey"))?.userRole ?? undefined;

  return (
    <>
      <div className="d-flex justify-content-end">
        <Row>
          <Col>

            <div className="d-flex ms-2 justify-content-end">
              {(assignedTome != undefined && userRole === "ADMIN") && <div className='' style={{
                display: "-webkit-inline-box"
              }}>
                <Form.Label className="me-2" >
                  My tickets
                </Form.Label>
                <ToggleSwitch
                  id="newsletter"
                  checked={assignedTome}
                  onChange={onSelectAssignedToMe}
                  optionLabels={["", ""]}
                />
              </div>}
              {selectFilteroption &&
                <Form.Select className='me-2' id="disabledSelect" value={selectFilteroption.value} onChange={(e) => {
                  onSelectFilteropion(e)
                }}
                  style={{ height: "41px", width: "147px" }}>
                  {
                    filterOptions?.map((item, idx) =>
                      <option key={idx} value={item?.value}>{item?.label}</option>
                    )
                  }

                </Form.Select>
              }
              {props.selectedDate && <Calendar
                selectedDate={props.selectedDate}
                onSelectDate={props.onSelectDate}
              />}
              {props.onSync && <Button style={{ height: "38px", marginRight: "20px" }} onClick={props.onSync}>Refresh</Button>}
              <select
                className="selectpage border me-1 "
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                style={{background:"content-box", color:"currentcolor"}}
              >
                {[10, 25, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
              <GlobalFilter
                filter={globalFilter}
                setFilter={setGlobalFilter}
              />
            </div>
            {props.type && <Badges type={props.type} onChangeHandler={onSelectFilteropion} />}
          </Col>
        </Row>
      </div></>
  )
}

export default TableHeader