import React from 'react'
import moment from "moment";

import { Badge, Button, Card, Col, Row, } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { badgecolors } from '../../components/Table/data';

const Notifications = (props) => {

  const { ticketHistory } = props
  return (

    <div>
      <Row className="">
        <Col sm={12} md={12} lg={12} xl={12}>
          <Card className=" custom-card">
            <Card.Body>
              <div>
                <div className="container"
                >
                  {ticketHistory && ticketHistory.length > 0 ?

                    <ul className="notification">
                      {ticketHistory?.map((i, idx) =>
                        <li key={idx}>
                          <div className="notification-time">
                            <span className="date">{moment(i.created_on, "DD-MM-YYYY HH:mm:ss")?.format("dddd")}</span>
                            <span className="time">{moment(i.created_on, "DD-MM-YYYY HH:mm:ss")?.format("hh:mm A")}</span>
                          </div>
                          <div className="notification-icon">
                            <Link to="#" className={`${badgecolors(i?.ticket_status)}`}></Link>
                          </div>
                          <div className="notification-body"
                            style={{ minHeight: "80px" }}
                          >
                            <div className="media mt-0  mt-sm-4  mt-sm-3">
                              <div className="media-body">
                                <div className="d-flex align-items-center">
                                  <div className="mt-0">
                                    <h5 className="mb-1 tx-15 font-weight-semibold text-dark">
                                    </h5>
                                    <p className="mb-0 tx-13 mb-0 text-muted">
                                      {i?.notes}
                                    </p>
                                  </div>
                                  <div className="ms-auto">
                                    <Badge bg="" className="float-end badge notification-badge">
                                      <span className="tx-11 font-weight-semibold">
                                        {moment(i.created_on, "DD-MM-YYYY HH:mm:ss A ")?.format("DD, MMM YYYY")}
                                      </span>
                                    </Badge>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                      }
                    </ul>
                    : <div className='text-primary'>
                      No Data found
                    </div>
                  }

                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

      </Row>
    </div>

  )
}

export default Notifications