import {
  ACTION_GET_DEPARTMENT_DETAILS_REQ,
  ACTION_GET_DASHBOARD_DEPARTMENT_DETAILS_REQ,
  ACTION_POST_DEPARTMENT_DETAILS_WITH_DATE_RANGE,
  ACTION_POST_GET_TODAY_REQ,
  APISTATUS,
  ACTION_POST_SAVEHOMGE_REQ
} from "./SagaActionTypes";

export const actionReqResStatusLoaderSagaAction = (payload) => {
  return {
    type: APISTATUS,
    payload: payload,
  };
};

export const actionGetDepartmentDetails = (payload) => {
  return {
    type: ACTION_GET_DEPARTMENT_DETAILS_REQ,
    payload: payload,
  };
};

export const actionGetDashboardDepartmentDetails = (payload) => {
  return {
    type: ACTION_GET_DASHBOARD_DEPARTMENT_DETAILS_REQ,
    payload: payload,
  };
};

export const actionPostDepartmentDetailsWithDateRange = (payload) => {
  return {
    type: ACTION_POST_DEPARTMENT_DETAILS_WITH_DATE_RANGE,
    payload: payload
  }
}


export const actionPostTodayData = (payload) => {
  return {
    type: ACTION_POST_GET_TODAY_REQ,
    payload: payload
  }
}

export const homepagedepartmentdetailsAction = (payload) => ({
  type: ACTION_POST_SAVEHOMGE_REQ,
  payload,
});