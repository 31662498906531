const twoLettersTxt = (txt) => {
    if (txt?.length === 1) {
        return (txt[0][0])?.toUpperCase()
    } else {
        return (txt[0][0] + txt[txt?.length - 1][0])?.toUpperCase()
    }
}

export const shortNames = (name) => {
    let trmTxt = name?.trim();
    if (trmTxt) {
        const findDotText = trmTxt?.includes(".");
        if (findDotText) {
            let finalTxt = trmTxt?.split(".")?.pop()?.trim();
            const splitTxt = finalTxt?.split(" ");
            return twoLettersTxt(splitTxt);
        } else {
            const splitname = trmTxt?.split(" ");
            return twoLettersTxt(splitname);
        }
    } else {
        return "NA"
    }
}

