import React from 'react';
import { Card } from 'react-bootstrap';

export const Cards = ({ item, handleImgClick, setImgShowPopup }) => {


  return (
    <div
    >
      <Card
        style={{
          borderRadius: '8px',
          // maxWidth: "min-content",
        }}
      >
        <table class="table table-striped roundedCorners"
          style={{
            marginBottom: "0",
            // minHeight: "65vh",
            maxWidth: "10vw"
            
          }}>
          <thead>
            <tr class="bg-primary"
              style={{ color: "white" }}
            >
              <th >Type</th>
              <th >Original</th>
              <th >Duplicate</th>
            </tr>
          </thead>
          <tbody>
            <tr >
              <td >{item?.attendeeid}</td>
              <td >{item?.attendeeid}</td>
              <td >{item?.dattendeeid}</td>

            </tr>
            <tr >
              <td >Attendee name	</td>
              <td >{item?.attendeename}</td>
              <td >{item?.dattendeename}</td>


            </tr>
            <tr >
              <td >School/Classname	</td>
              <td >{item?.college_or_classname}</td>
              <td >{item?.dcollege_or_classname}</td>

            </tr>
            <tr >
              <td>Designation</td>
              <td>{item?.designation}</td>
              <td>{item?.ddesignation}</td>
            </tr>
            <tr >
              <td >Thumbnail</td>
              <td
               onClick={() => handleImgClick(item?.img1, item?.img2)}
              >{item?.thumbnail}</td>
              <td 
               onClick={() => handleImgClick(item?.img1, item?.img2)}
               >{item?.dthumbnail}</td>
            </tr>
            <tr >
              <td >Action</td>
              <td
                colSpan={"2"}
              >
                {item?.apreject}
              </td>

            </tr>
          </tbody>
        </table>
      </Card>
    </div>
  )
}



export const thumbnailToimg = (url) => {
  return (
    <div style={{
      alignItems: "left",
    }}>
      <img
        src={url}
        // className='orgDuplicate'
        style={{
          objectFit: "cover",
          borderRadius: ".3rem",
          width:" 120px",
      height:" 80px",
      cursor:"pointer"
    
        }}
      />
    </div>
  );
};

export const ApproveReject = ({data,popUPShow}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <div>

        <button type="button" class="btn btn-primary"
          onClick={
            () =>popUPShow("1", data)
          }

          style={{ minWidth: "10px" ,marginRight:"16px",}}>
          <svg
            fill="white"
            width="20"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
          </svg>
        </button>
      </div>
      <div>

        <button type="button "
          onClick={ () =>popUPShow("0", data)}

          class="btn btn-danger btn-sm" style={{ marginLeft: "4px",marginRight:"16px", }}>
          <svg
            fill="white"
            width="30"
            height="30"
            viewBox="0 0 24 24"
          >
            <path
              d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 
            9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69
      0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425
      2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727
      2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728
      2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531
      0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
              fillRule="nonzero"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
