import React, { useEffect } from 'react'
import ReportTable from '../../components/Table'
import { Button, Card, Col, Form, FormGroup, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import Calendar from "../../components/Calendar"
import AttendeeSummaryPresenter from './AttendeeSummaryPresenter'
import { RightArrowSvg, RightForwardArrowSvg } from '../../assets/svgs/Svg'


const AttendeeDetails = () => {

  const {
    AttendanceSearchOptions,
    breadcrumData,
    nextDisabled,
    onSelectOrg,
    onChildNavigation,
    Breadcrumbs,
    attendanceSummaryTableData,
    attendanceSummaryData,
    attendancePersonalData,
    selectedToDate,
    selectedStaff,
    selectedFromDate,
    onSelectToDate,
    callingAttendaceSummaryData,
    handleClick,
    staffListData,
    onSelectFromDate,
    HideNavigationMenu,
  } = AttendeeSummaryPresenter()

  const ToolTipTrigger = (text, Icon) => {
    return (

      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip id="button-tooltip">{text}</Tooltip>}
      >
        {Icon}
      </OverlayTrigger>
    );

  };


  return (
    <div>
      <div className='d-flex justify-content-between'>
        <h5 className="mt-3 mb-3">Attendee Summary Report</h5>
      </div>
      <Row className="row-sm">
        <Col xl={12} lg={12} md={12} sm={12}>
          <Card>
            <Col xl={9} lg={9} md={9} sm={12}>
              {Breadcrumbs()}
            </Col>
            {HideNavigationMenu === false ?
              <ul >
                {AttendanceSearchOptions?.map((i, idx) => <li
                  key={i?.orgid}

                  style={{
                    marginRight: "8px",
                    listStyleType: "none",
                    cursor: "pointer"
                  }}
                >
                  <span
                    className='underline'
                    onClick={() => {
                      onSelectOrg(i)
                    }}
                  >
                    {i?.label}
                  </span>
                  {/* <i
                    class="ti-arrow-right "
                    style={{ marginLeft: "8px", fontSize: "12px", cursor: "pointer" }}
                    onClick={() => {
                      onChildNavigation(i)
                    }}
                  ></i> */}
                  {
                    ToolTipTrigger(
                      "Go Inside", <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" onClick={() => {
                        onChildNavigation(i)
                      }}><path fill="#888888" d="M2 11h12.2l-5.6 5.6L10 18l8-8l-8-8l-1.4 1.4L14.2 9H2z"></path></svg>

                    )
                  }
                  {/* <RightForwardArrowSvg
                    onClick={() => {
                      onChildNavigation(i)
                    }} /> */}
                </li>)}
              </ul>
              : <></>}
          </Card>
        </Col>
      </Row>
      {
        staffListData?.length > 0 && nextDisabled?.label !== undefined ?
          <ReportTable
            title={`Staff List of ${nextDisabled?.label}`}
            tableData={staffListData}
            clickableColumns={[[0, 1, 2], handleClick]}
            StyledColumns={[
              [0, 1, 2, 9],
              [" underline", "underline", "underline", "wrap"],
            ]}
            fileName={`Staff List of ${nextDisabled?.label}`}
          />
          :
          <></>
      }
      {
        nextDisabled?.value && (staffListData.length > 0) ?
          <Card className="col-12"
            style={{ marginTop: "1rem" }}>
            <Row className="">
              <FormGroup className="text-start form-group col-12 col-sm-3">
                <Form.Label>Id</Form.Label>
                <input placeholder="Select id from table above" type="text" class="form-control form-control"
                  readOnly
                  value={selectedStaff?.["Att Name"]}
                >
                </input>
              </FormGroup>
              <FormGroup className="text-start form-group col-12 col-sm-3">
                <Form.Label>From</Form.Label>
                <div className="mb-4" style={{ "marginRight": "-50px" }}>
                  <Calendar
                    selectedDate={selectedFromDate}
                    onSelectDate={onSelectFromDate}
                  />
                </div>
              </FormGroup>
              <FormGroup className="text-start form-group col-12 col-sm-3">
                <Form.Label>To</Form.Label>
                <div className="mb-4" >
                  <Calendar
                    selectedDate={selectedToDate}
                    onSelectDate={onSelectToDate}
                  />
                </div>
              </FormGroup>
              <FormGroup className="text-start form-group col-12 col-sm-3 mt-3">
                <div className="mb-4 mt-3" >
                  <Button type="button"
                    onClick={() => callingAttendaceSummaryData()}
                  >Submit</Button>
                </div>
              </FormGroup>
            </Row>
          </Card>
          : <></>
      }

      {
        attendanceSummaryTableData?.length > 0 && nextDisabled?.label !== undefined ?
          <>
            <Row className=" row-sm">
              <Col md={12} xl={6} lg={6} xxl={6}>
                <Card className="custom-card">
                  <Card.Body>
                    <h4 className="card-title">Employee Details</h4>
                    <div className="">
                      <Row className="">
                        <Col xl={12}>
                          <div className="table-responsive">
                            <Table className="table mb-0 table-bordered text-nowrap">
                              <tbody>
                                <tr>
                                  <th scope="row">Name</th>
                                  <td>{attendancePersonalData?.staffName}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Mobile No</th>
                                  <td>{attendancePersonalData?.mobileNo}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Email</th>
                                  <td>{attendancePersonalData?.email}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Department</th>
                                  <td>{attendancePersonalData?.department}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Designation</th>
                                  <td>{attendancePersonalData?.designation}</td>
                                </tr>

                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className=" row-sm">
              <Col >
                <Card className="custom-card">
                  <Card.Body>
                    <h4 className="card-title">Summary</h4>
                    <div className="">
                      <div className="main-content-body main-content-body-contacts card custom-card">
                        <div className="main-contact-info-body">
                          <div className="media-list pb-0">
                            <div className="media">
                              <div className="media-body">
                                <div className='d-flex'>
                                  <label>Working Days:</label>{" "}
                                  <span className="tx-medium ms-1" style={{ fontSize: "13px" }}>{attendanceSummaryData?.workingDays}</span>
                                </div>
                                <div className='d-flex'>
                                  <label>Approved Leaves:</label>{" "}
                                  <span className="tx-medium ms-1" style={{ fontSize: "13px" }}>{attendanceSummaryData?.approvedLeaves}</span>
                                </div>
                                <div className='d-flex'>
                                  <label>Present Days:</label>{" "}
                                  <span className="tx-medium ms-1" style={{ fontSize: "13px" }}>{attendanceSummaryData?.present}</span>
                                </div>
                                <div className='d-flex'>
                                  <label>Absent Days:</label>{" "}
                                  <span className="tx-medium ms-1" style={{ fontSize: "13px" }}>{attendanceSummaryData?.absent}</span>
                                </div>
                              </div>
                            </div>
                            <div className="media">
                              <div className="media-body">
                                <div className='d-flex'>
                                  <label>Late In Count:</label>{" "}
                                  <span className="tx-medium ms-1" style={{ fontSize: "13px" }}>
                                    {attendanceSummaryData?.lateIn}
                                  </span>
                                </div>
                                <div className='d-flex'>
                                  <label>Early Out Count:</label>{" "}
                                  <span className="tx-medium ms-1" style={{ fontSize: "13px" }}>
                                    {attendanceSummaryData?.earlyOut}
                                  </span>
                                </div>
                                <div className='d-flex'>
                                  <label>Single Punch:</label>{" "}
                                  <span className="tx-medium ms-1" style={{ fontSize: "13px" }}>
                                    {attendanceSummaryData?.singlePunch}
                                  </span>
                                </div>
                                <div className='d-flex'>
                                  <label>Holidays:</label>{" "}
                                  <span className="tx-medium ms-1" style={{ fontSize: "13px" }}>
                                    {attendanceSummaryData?.totalHolidays}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="media">
                              <div className="media-body">

                                <div className='d-flex'>
                                  <label>Expected Work Hours:</label>{" "}
                                  <span className="tx-medium ms-1" style={{ fontSize: "13px" }}>
                                    {attendanceSummaryData?.expectedHours}
                                  </span>
                                </div>
                                <div className='d-flex'>
                                  <label>Actual Worked Hours: </label>{" "}
                                  <span className="tx-medium ms-1" style={{ fontSize: "13px" }}>
                                    {attendanceSummaryData?.workedHours}
                                  </span>
                                </div>
                                <div>
                                </div>
                                <div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                    </div>
                  </Card.Body>

                </Card>
              </Col>
            </Row>
            <ReportTable
              title={'Attendance Details'}
              tableData={attendanceSummaryTableData}
              fileName={`${attendancePersonalData?.staffName}Attendance Details`}
            />
          </>
          :
          <></>
      }
    </div>
  )
}

export default AttendeeDetails