import {
    ACTION_POST_ADMIN_NOTIFICATION_LIST_REQ,
    ACTION_POST_ADMIN_NOTIFICATION_READ_LIST_REQ,
    ACTION_POST_ADMIN_PUSH_NOTIFICATION_REQ,
    ACTION_POST_NOTIFICATION_ADD_REQ,
    ACTION_POST_NOTIFICATION_DELETE_REQ,
    ACTION_POST_NOTIFICATION_LIST_REQ,
    ACTION_POST_NOTIFICATION_LOGS_REQ,
    ACTION_POST_NOTIFICATION_UPDATE_REQ,
    ACTION_POST_PUSH_NOTIFICATION_REQ,
    ACTION_POST_SEND_SMS_REQ,
    ACTION_POST_SEND_WHATSAPP_NOTIFICATION_REQ,
    ACTION_POST_SMS_TEMPLATE_NOTIFICATION_REQ,
    ACTION_POST_WHATSAPP_NOTIFICATION_REQ
} from "./SagaActionTypes";

export const notificationListAction = (payload) => ({
    type: ACTION_POST_NOTIFICATION_LIST_REQ,
    payload,
});
export const notificationAddAction = (payload) => ({
    type: ACTION_POST_NOTIFICATION_ADD_REQ,
    payload,
});
export const notificationUpdateAction = (payload) => ({
    type: ACTION_POST_NOTIFICATION_UPDATE_REQ,
    payload,
});
export const notificationDeleteAction = (payload) => ({
    type: ACTION_POST_NOTIFICATION_DELETE_REQ,
    payload,
});
export const whatsAppNotificationAction = (payload) => ({
    type: ACTION_POST_WHATSAPP_NOTIFICATION_REQ,
    payload,
});
export const smsTemplatesNotificationAction = (payload) => ({
    type: ACTION_POST_SMS_TEMPLATE_NOTIFICATION_REQ,
    payload,
});
export const sendSmsAction = (payload) => ({
    type: ACTION_POST_SEND_SMS_REQ,
    payload,
});
export const notificationLogsAction = (payload) => ({
    type: ACTION_POST_NOTIFICATION_LOGS_REQ,
    payload,
});
export const pushNotificationAction = (payload) => ({
    type: ACTION_POST_PUSH_NOTIFICATION_REQ,
    payload,
});
export const sendWhatsAppNotificationAction = (payload) => ({
    type: ACTION_POST_SEND_WHATSAPP_NOTIFICATION_REQ,
    payload,
});
export const adminPushNotificationAction = (payload) => ({
    type: ACTION_POST_ADMIN_PUSH_NOTIFICATION_REQ,
    payload,
});
export const adminNotificationListAction = (payload) => ({
    type: ACTION_POST_ADMIN_NOTIFICATION_LIST_REQ,
    payload,
});
export const adminNotificationReadListAction = (payload) => ({
    type: ACTION_POST_ADMIN_NOTIFICATION_READ_LIST_REQ,
    payload,
});
