import React, { useState,useEffect } from 'react';
import { Modal,Button } from "react-bootstrap";
import ImageSlider from '../ImageSlider/ImageSlider';
import { useIsMobile } from "../../utils/hooks";
import { groupAttendanceListAction } from '../../Store/SagaActions/TableDetailSagaAction';
import { useDispatch } from 'react-redux'

const GroupImageModal  = ( props ) => {

    const { 
        showPopup,
        closeModal,
        orgid,
        attendanceDate         
         } = props

         const [nameClass, setNameClass] = useState("84px");
         const isMobile = useIsMobile();
         const dispatch = useDispatch();
         const [groupAttendanceData, setGroupAttendanceData] = useState({})
         const [isApiCallDone,setIsApiCallDone] = useState(false);

         useEffect(() => {
         
             if (isMobile) {
                 setNameClass("201px")
             }
             else {
                 setNameClass("84px")
             }

             if (showPopup) {

              // console.log(orgid + " **** " + attendanceDate);

               let model = {
                "orgid": orgid ,
                "attendance_date":attendanceDate 
              }

              setIsApiCallDone(false);

              dispatch(
                groupAttendanceListAction({
                  model,
                  callback: async (data) => {
                    if (data?.status || data?.details?.length === 0) {
                     console.log("No  data");
                      return
                    }
                    setGroupAttendanceData(data);
                    setIsApiCallDone(true);
                  },
                })
              );
             }
         }, [showPopup,isMobile]);
     
  return (

    <Modal
    show={showPopup}
    size="lg"
    aria-labelledby="example-modal-sizes-title-lg"
    centered
    style={{ zIndex: "9991" }}
   >
     <Modal.Header style={{ borderBottom: "0.6px solid #999999" }}>
         <Modal.Title className="text-center"> Group Attendance Image </Modal.Title>
            <Button variant="" className="btn btn-close" onClick={() => { closeModal() }} > x </Button>
     </Modal.Header>
     <Modal.Body className="pt-1 ">
      {
        isApiCallDone?<>
       {
        (groupAttendanceData.length)>0?<>
        <ImageSlider groupImages={groupAttendanceData} />
        </>
        :<h6 style={{ color: "red"}}>{`Group Attendance not taken`}</h6>
       }</>
       :
       <h6>Loading ......</h6>
       }
     </Modal.Body>
   </Modal> 
  );
};

export default GroupImageModal ;
