import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import { downloadExcel } from "react-export-table-to-excel";

export const exportToCSV1 = (tabledata, fileName) => {
    let table_headers = Object.keys(tabledata[0])
    var Heading = [table_headers];
    const ws = XLSX.utils.book_new();
    ws['!cols'] = [{ width: 20 }, { width: 30 }, { width: 60 }, { width: 20 }]; //set col. widths
    XLSX.utils.sheet_add_aoa(ws, Heading);
    var wf = XLSX.utils.sheet_add_json(ws, tabledata, { origin: 'A2', skipHeader: true });
    XLSX.utils.json_to_sheet(tabledata);
    const wb = { Sheets: { data: wf }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array", cellStyles: true });
    const data = new Blob([excelBuffer], { type: "xlsx" });
    FileSaver.saveAs(data, `${fileName}` + " List" + ".xlsx");

}


export const exportToCSV = (tabledata, fileName, type) => {
    // if (type === "student") { 
    //     exportToCSV1(tabledata, fileName)
    //     return
    // }
    let header = Object.keys(tabledata[0])
    downloadExcel({
        fileName: fileName,
        sheet: "Sheet 1",
        tablePayload: {
            header,
            // accept two different data structures
            body: tabledata,
        },
    });


}

