import React from "react";
import { Card } from "react-bootstrap";
import { useEffect, useState } from "react";
import ReportTable from "../components/Table";
import ModalDetail from "../feature/Grivences";
import { useDispatch } from "react-redux";
import {
  grivenceAll,
  grivencetickethistory,
  grivenceticketassign,
  grivenceticketassigndropdown,
  grivenceticketinprogress,
  grivenceticketpending,
  grivenceticketclosed,
  grivenceassignedtome,
  grivenceprincipal
} from "../Store/SagaActions/GrivenceSagaActions";
import { Assign, tabledata } from "../utils";
import { toast } from "react-hot-toast";
import moment from "moment";
import LoaderComp from "../components/LoaderComp/LoaderComp";
import ToggleSwitch from "../components/Toggleswitch";

function Grivences() {
  const dispatch = useDispatch();
  const userRole = JSON.parse(sessionStorage.getItem("authkey"))?.userRole ?? undefined;
  const [clickedColumn, setClickedColumn] = useState("")
  const [TicketData, setTicketData] = useState({}); // ticket details
  const [tableFullData, setTableFullData] = useState([]); // full table unchanged data
  const [grivences, setgrivences] = useState([]); //table data
  const [Assignlist, setAssignlist] = useState([]); // list of ticket handlers
  const [showPopup, setShowPopup] = useState(false); // show modal
  const [clickRow, setClickRow] = useState(null);
  const [assignedTome, setAssignedTome] = useState(false)  // for state dept toggle button
  const [ticketHistory, setticketHistory] = useState([]);
  const [selectedOption, setSelectedOption] = useState(() => Assignlist[0]);
  const [descriptionOption, setDescriptionOption] = useState([]);
  const [filterOptions, setFilterOptions] = useState([
    { value: "0", label: "ALL", short: `ALL` },
    { value: "1", label: "Assign", short: `Assign` },
    { value: "2", label: "In Progress", short: `In Progress` },
    { value: "3", label: "Pending", short: `Pending` },
  ]);
  const [selectFilteroption, setSelectFilteroption] = useState(
    filterOptions[0]
  );
  const [showDropdown, setshowDropdown] = useState(false)
  const [descriptionSelects, setDescriptionSelects] = useState(false)
  const[hideTableData,setHideTableData]=useState(false)

  useEffect(() => {
    reloadTable();
  }, []);


  useEffect(() => {
    // filtering DELHI admin from TICkET LIST
    let res = Assignlist.filter(x => x.value != 2);
    setAssignlist(res);
  }, [Assignlist])
  
  // refresh table intially or after an action like in progress.pending/close
  function reloadTable(type) {
    setDescriptionSelects(false)
    let college_orgid = JSON.parse(sessionStorage.getItem("authkey")).orgid
    let period = '900'
    let model = { period }
    switch (userRole) {
      case "ADMIN":
        dispatch(
          grivenceAll({
            model,
            callback: (data) => {
              if (type === "historyTab") {
                tabledata(data, setgrivences, setTableFullData, clickRow, setTicketData);
                document.getElementById("left-tabs-example-tab-History").click()
              }
              else {
                tabledata(data, setgrivences, setTableFullData, clickRow, setTicketData);
              }
            },
          })
        );
        return;
      case "STATE":
        dispatch(
          grivenceAll({
            model,
            callback: (data) => {
              tabledata(data, setgrivences, setTableFullData, clickRow, setTicketData);
            },
          })
        );
        return;
      case "PRINCIPAL":
        model = { period, college_orgid };
        dispatch(
          grivenceprincipal({
            model,
            callback: (data) => {
              tabledata(data, setgrivences, setTableFullData, clickRow, setTicketData);
            },
          })
        );
        return
      default:
        toast.error("Something Unexpected happened Please try again")
        return;
    }
  };

  // assigned to me toggle button api call 
  function onSelectAssignedToMe() {
    setAssignedTome(!assignedTome)
    let model = { period: "90" };
    if (assignedTome === false) {
      dispatch(
        grivenceassignedtome({
          model,
          callback: (data) => {
            if (data?.grievancedetails.length === 0) {
              toast("No Grivances has beenn assigned to You")
              return
            }
            tabledata(data, setgrivences, setTableFullData, clickRow, setTicketData);
          },
        })
      );
      return
    }
    reloadTable()
  }

  const onSelectAssigndropdown = (e) => {
    setSelectedOption(e);
  };
  const selectdescriptiondropdown = (e) => {
    if (TicketData?.status === "2" && e.label === "In Progress") {
      setDescriptionSelects(true)
    }
    else {
      setDescriptionSelects(false)
    }
    setDescriptionOption(e);
  };

  const tabledata = (data, setgrivences, setTableFullData, clickRow, setTicketData) => {
    setTableFullData(data?.grievancedetails) //data for the modal
    if (clickRow !== null) setTicketData(data?.grievancedetails[clickRow]);
    const mappeddata = (data?.grievancedetails.filter(i => i.status !== "3").sort(function (a, b) {
      return new Date(moment(b.ticket_date, "DD-MM-YYYY HH:mm:ss")) - new Date(moment(a.ticket_date, "DD-MM-YYYY HH:mm:ss"));
    }).map((i, idx) => {
      const { ticket_ID, ticket_date, college_code, college_name, raised_by, mobile, grivenceType, grivenceText, status, closed_date, closed_by, assignee } = i
      const temp = {
        "Ticket ID": ticket_ID,
        "School name": college_name,
        "mobile": mobile,
        "raised by": raised_by,
        "Raised Date": moment(ticket_date, "DD-MM-YYYY HH:mm:ss")?.format("DD-MM-YYYY"),
        "grievance Type": grivenceType,
        "Issue Description": grivenceText,
        "Closed By": closed_by === "--" ? "-" : closed_by,
        "Closed date": closed_date === "--" ? "-" : moment(closed_date, "DD-MM-YYYY HH:mm:ss ")?.format("DD-MM-YYYY"),
        "assignee": assignee === "--" ? "-" : assignee,
        status: Assign(status),
      };
      return temp;
    }));
    setgrivences(mappeddata)
  }

  //assigning open ticket
  const AssignHandler = (e) => {
    if (e === true) setReAssigneShow(false)
    const model = {
      ticket_id: TicketData.ticket_ID,
      assigned_uid: selectedOption?.assigned_uid,
    };
    dispatch(
      grivenceticketassign({ model, callback: () => reloadTable() })
    );
  };

  // table row click
  const handleClick = (e, data, clickedRow, ColumnName, ColumnNumber) => {
    if (data?.status === "In Progress") setDescriptionOption({ value: "1", label: "In Progress" })
    if (data?.status === "Pending") setDescriptionOption({ value: "2", label: "Pending" })
    if (data?.status === "Closed") setDescriptionOption({ value: "3", label: "Closed" })
    setClickedColumn(ColumnNumber)
    const ticket = tableFullData.find((i, idx) => {
      if (i.ticket_ID === data["Ticket ID"]) setClickRow(idx)
      return (i.ticket_ID === data["Ticket ID"])
    })
    setShowPopup(true);
    setTicketData(ticket);
    if (Assignlist.length === 0 && userRole === "ADMIN") {
      let model = {};
      dispatch(
        grivenceticketassigndropdown({
          model,
          callback: (data) => {
            const list = data?.grievanceusersdetails?.map((i, idx) => {
              const temp = {
                value: idx, label: i.username, assigned_uid: i.uid,
              }
              return (temp)
            })
            setAssignlist(list);
          },
        })
      );
    }
    //setting selected option
    setSelectedOption(Assignlist.find(i => (ticket?.assignee === i.label)))
  };

  //for changing status and conversation
  const ChatNstatus = (reqStatus, notes_text) => {
    if (descriptionSelects === true) {
      const model = {
        ticket_id: TicketData.ticket_ID,
        assigned_uid: selectedOption?.assigned_uid,
      };
      dispatch(
        grivenceticketassign({
          model, callback: () => { reloadTable("historyTab") }
        })
      );
    }
    else {
      let model = {
        ticket_id: TicketData?.ticket_ID,
        notes_text,
      };
      const type = (reqStatus > parseInt(TicketData.status)) ? `${reqStatus}` : "1"
      switch (type) {
        case "1":
          dispatch(grivenceticketinprogress({ model, callback: () => reloadTable(("historyTab")) }));
          return;
        case "2":
          dispatch(grivenceticketpending({ model, callback: () => reloadTable(("historyTab")) }));
          return;
        case "3":
          dispatch(grivenceticketclosed({ model, callback: () => reloadTable(("historyTab")) }));
          return;
        default:
          return "Invalid Request";
      }
    }
  };

  //filter for table
  const onSelectFilteropion = (e) => {
    const { value } = e?.target
    const selectedIndex = e.target.selectedIndex
    const label = e.target[selectedIndex].label
    setSelectFilteroption({ value, label, short: label });
  };

  function TicketHistory(data) {
    setticketHistory(data?.grievancetickethistorydetails)
  }

  const fetchTicketHistory = () => {
    const model = {
      ticket_id: TicketData?.ticket_ID,
    };
    dispatch(
      grivencetickethistory({
        model,
        callback: TicketHistory,
      })
    );
  };
  //for reassigne the grevience
  const [reAssigneShow, setReAssigneShow] = useState(false);
  const reAssigne = () => {
    if (["1", "2", "0"].includes(TicketData.status) === true) setReAssigneShow(true)
  }
 
  return (
    <>
      <LoaderComp />
      <ModalDetail
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        TicketData={TicketData}
        ticketHistory={ticketHistory}
        Assignlist={Assignlist}
        AssignHandler={AssignHandler}
        ChatNstatus={ChatNstatus}
        fetchTicketHistory={fetchTicketHistory}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        onSelectAssigndropdown={onSelectAssigndropdown}
        descriptionOption={descriptionOption}
        setDescriptionOption={setDescriptionOption}
        selectdescriptiondropdown={selectdescriptiondropdown}
        showDropdown={showDropdown}
        setshowDropdown={setshowDropdown}
        reAssigne={reAssigne}
        reAssigneShow={reAssigneShow}
        setReAssigneShow={setReAssigneShow}
        clickedColumn={clickedColumn}
        descriptionSelects={descriptionSelects}
        setDescriptionSelects={setDescriptionSelects}
      />
      <Card
        style={{ marginTop: "2rem", marginBottom: "2rem", padding: "1rem" }}
      >
        {tableFullData.length > 0 ? (
          <ReportTable
            title={`List of grievances`}
            tableData={(grivences.length===0 && tableFullData.length>0)?tableFullData:grivences}
            clickableColumns={[[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10], handleClick]}
            StyledColumns={[
              [0, 1, 2, 3, 4, 5, 6, 7, 8, 10],
              ["underline", "underline wrap", " ", "", " ", " wrap", " wrap", "", "", "badges1 w-100"],
            ]}
            filterOptions={filterOptions}
            selectFilteroption={selectFilteroption}
            onSelectFilteropion={onSelectFilteropion}
            badgeBG={true}
            assignedTome={assignedTome}
            onSelectAssignedToMe={onSelectAssignedToMe}
            fileName={"Grievances "}
            hideTableData={grivences.length===0 && tableFullData.length>0}

          />
        ) :
          <div className="text-primary"
          >No Data Found
          </div>
        }
      </Card>
    </>
  );
}

export default Grivences;
