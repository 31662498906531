import React, { useEffect,useState } from 'react'
import ReportTable from '../../components/Table'
import { Button, Card, Col, Form, FormGroup, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import Calendar from "../../components/Calendar"
import StaffClassAttendancePresenter from './StaffClassAttendancePresenter'
import { RightArrowSvg, RightForwardArrowSvg } from '../../assets/svgs/Svg'
import ClassGroupImageModal from '../../components/Modals/ClassGroupImageModal'

const StaffClassAttendeeDetails = () => {
  const [gid, setGid] = useState('');
  const [showPopup, setShowPopup] = useState(false); // show modal

  const updateShowPopup = (newValue, gid) => {
    setShowPopup(newValue);
    setGid(gid);
    
};
  const {
    AttendanceSearchOptions,
    breadcrumData,
    nextDisabled,
    onSelectOrg,
    onChildNavigation,
    Breadcrumbs,
    attendanceSummaryTableData,
    attendanceSummaryData,
    attendancePersonalData,
    selectedToDate,
    selectedStaff,
    selectedFromDate,
    onSelectToDate,
    callingAttendaceSummaryData,
    handleClick,
    staffListData,
    onSelectFromDate,
    HideNavigationMenu,
    staffName,
    handleImageClick
  } = StaffClassAttendancePresenter(updateShowPopup)

  const ToolTipTrigger = (text, Icon) => {
    return (

      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip id="button-tooltip">{text}</Tooltip>}
      >
        {Icon}
      </OverlayTrigger>
    );

  };

  const closeModal = () => {
    setShowPopup(false);
  }

  return (
    <>
    <ClassGroupImageModal 
    showPopup={showPopup}
    closeModal={closeModal}
    gid={gid}
   />

    <div>
      <div className='d-flex justify-content-between'>
        <h5 className="mt-3 mb-3">Staff Class Attendance Report</h5>
      </div>
      <Row className="row-sm">
        <Col xl={12} lg={12} md={12} sm={12}>
          <Card>
            <Col xl={9} lg={9} md={9} sm={12}>
              {Breadcrumbs()}
            </Col>
            {HideNavigationMenu === false ?
              <ul >
                {AttendanceSearchOptions?.map((i, idx) => <li
                  key={i?.orgid}

                  style={{
                    marginRight: "8px",
                    listStyleType: "none",
                    cursor: "pointer"
                  }}
                >
                  <span
                    className='underline'
                    onClick={() => {
                      onSelectOrg(i)
                    }}
                  >
                    {i?.label}
                  </span>
                  {/* <i
                    class="ti-arrow-right "
                    style={{ marginLeft: "8px", fontSize: "12px", cursor: "pointer" }}
                    onClick={() => {
                      onChildNavigation(i)
                    }}
                  ></i> */}
                  {
                    ToolTipTrigger(
                      "Go Inside", <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" onClick={() => {
                        onChildNavigation(i)
                      }}><path fill="#888888" d="M2 11h12.2l-5.6 5.6L10 18l8-8l-8-8l-1.4 1.4L14.2 9H2z"></path></svg>

                    )
                  }
                  {/* <RightForwardArrowSvg
                    onClick={() => {
                      onChildNavigation(i)
                    }} /> */}
                </li>)}
              </ul>
              : <></>}
          </Card>
        </Col>
      </Row>
      {
        staffListData?.length > 0 && nextDisabled?.label !== undefined ?
          <ReportTable
            title={`Staff List of ${nextDisabled?.label}`}
            tableData={staffListData}
            clickableColumns={[[0, 1, 2], handleClick]}
            StyledColumns={[
              [0, 1, 2, 9],
              [" underline", "underline", "underline", "wrap"],
            ]}
            fileName={`Staff List of ${nextDisabled?.label}`}
          />
          :
          <></>
      }
      {
        nextDisabled?.value && (staffListData.length > 0) ?
          <Card className="col-12"
            style={{ marginTop: "1rem" }}>
            <Row className="">
              <FormGroup className="text-start form-group col-12 col-sm-3">
                <Form.Label>Id</Form.Label>
                <input placeholder="Select id from table above" type="text" class="form-control form-control"
                  readOnly
                  value={selectedStaff?.["Att Name"]}
                >
                </input>
              </FormGroup>
              <FormGroup className="text-start form-group col-12 col-sm-3">
                <Form.Label>Date</Form.Label>
                <div className="mb-4" style={{ "marginRight": "-50px" }}>
                  <Calendar
                    selectedDate={selectedFromDate}
                    onSelectDate={onSelectFromDate}
                  />
                </div>
              </FormGroup>
     
              <FormGroup className="text-start form-group col-12 col-sm-3 mt-3">
                <div className="mb-4 mt-3" >
                  <Button type="button"
                    onClick={() => callingAttendaceSummaryData()}
                  >Submit</Button>
                </div>
              </FormGroup>
            </Row>
          </Card>
          : <></>
      }

      {
        attendanceSummaryTableData?.length > 0 && nextDisabled?.label !== undefined ?
          <>
            <ReportTable
              title={'Class Attendance Details'}
              clickableColumns={[[1], handleImageClick]}
              StyledColumns={[[1],[" underline", "underline"],
            ]}
              tableData={attendanceSummaryTableData}
              fileName={`${staffName}_Class_Attendance_Details`}
            />
          </>
          :
          <></>
      }
    </div>
    </>
  )
}

export default StaffClassAttendeeDetails