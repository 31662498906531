import {toast} from "react-hot-toast";
import { put, takeLatest, call } from "redux-saga/effects";
import{
  ACTION_POST_ATTENDEE_MANGEMENT_DISTRICT_LIST_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_MANDAL_LIST_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_ADD_ORG_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_DEPARTMENT_COLLEGES_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_ADD_CLASS_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_ADD_STAFF_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_STAFF_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_COLLEGE_CLASS_LIST_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_ADD_STUDENT_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_STUDENT_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_DESIGNATION_MASTER_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_SINGLE_STUDENT_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_SINGLE_STAFF_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_ADD_UPDATE_DESIGNATION_REQ,
  ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_GEO_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_STUDENT_LIST_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_STAFF_LIST_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_ATTENDEE_STATUS_REQ ,
  ACTION_POST_ATTENDEE_MANGEMENT_ROLE_LIST_REQ,
     
} from "../SagaActions/SagaActionTypes";

import {getAPI, postAPI} from "./ApiMethods";
import { actionReqResStatusLoaderSagaAction } from "../SagaActions/DepartmentSagaAction";

function* postAttendeeMangementDistrict(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/departmentwise-districts",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }

  function* postAttendeeMangementMandal(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/mandals",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }
  function* postattendeeAddOrg(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/addorganisation",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }

  function* postattendeeDeptCollege(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/departmentwise-colleges",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }

  function* postattendeeAddClass(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/addclass",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }

  function* postattendeeAddStaff(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/addstaff",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }

  function* postattendeeUpdateStaff(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/updatestaff",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }

  function* postattendeeCollegeClassList(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/collegewise-classes",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }
  function* postattendeeAddStudent(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/addstudent",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }
  function* postattendeeUpdateStudent(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/updatestudent",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }

  function* postattendeeDesignationMaster(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/designationmaster",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }

  function* postattendeeSingleStudent(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/getstudentdata",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }
  function* postattendeeSingleStaff(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/getstaffdata",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }
  function* postattendeeAddUpdateDesignation(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/addupdatedesignation",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }
  function* postattendeeUpdateGeo(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/updategeofencing",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }
  function* postattendeeStudentList(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/studentlist",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }
  function* postattendeeStaffList(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/stafflist",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }

  function* postattendeeUpdateStatus(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/updateattendeestatus",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }

  function* postattendeeRoleList(action) {
    yield put(actionReqResStatusLoaderSagaAction(true));
    const resp = yield call(
      postAPI,
      process.env.REACT_APP_BASE_URL +
      "/api/attendeemanagement/roleslist",
      action?.payload?.model
    );
    try {
      yield put(actionReqResStatusLoaderSagaAction(false));
      action?.payload?.callback(resp);
    } catch (err) {
      yield put(actionReqResStatusLoaderSagaAction(false));
      if (err.response) {
        action?.payload?.callback([]);
        toast.error(
          err?.response?.data?.errors?.length &&
          err?.response?.data?.errors[0]?.message
          );
        }
    } finally {
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  }





export default function* AttendeeMangementSaga() {
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_DISTRICT_LIST_REQ, postAttendeeMangementDistrict);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_MANDAL_LIST_REQ, postAttendeeMangementMandal);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_ADD_ORG_REQ, postattendeeAddOrg);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_DEPARTMENT_COLLEGES_REQ, postattendeeDeptCollege);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_ADD_CLASS_REQ, postattendeeAddClass);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_ADD_STAFF_REQ, postattendeeAddStaff);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_STAFF_REQ, postattendeeUpdateStaff);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_COLLEGE_CLASS_LIST_REQ, postattendeeCollegeClassList);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_ADD_STUDENT_REQ, postattendeeAddStudent);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_STUDENT_REQ, postattendeeUpdateStudent);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_DESIGNATION_MASTER_REQ, postattendeeDesignationMaster);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_SINGLE_STUDENT_REQ, postattendeeSingleStudent);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_SINGLE_STAFF_REQ, postattendeeSingleStaff);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_ADD_UPDATE_DESIGNATION_REQ, postattendeeAddUpdateDesignation);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_GEO_REQ, postattendeeUpdateGeo);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_STUDENT_LIST_REQ, postattendeeStudentList);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_STAFF_LIST_REQ, postattendeeStaffList);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_UPDATE_ATTENDEE_STATUS_REQ, postattendeeUpdateStatus);
    yield takeLatest(ACTION_POST_ATTENDEE_MANGEMENT_ROLE_LIST_REQ, postattendeeRoleList);
  }