import { call, takeEvery, put } from "redux-saga/effects";
import { actionReqResStatusLoaderSagaAction } from "../SagaActions/DepartmentSagaAction";
import {
  ACTION_POST_DEPARTMENT_DETAILS_WITH_DATE_RANGE,
  ACTION_POST_GET_TODAY_REQ,
} from "../SagaActions/SagaActionTypes";
import { postAPI } from "./ApiMethods";
// const API_URL = "https://frsdegree.ap.gov.in";

function* getDeparmentDetailsWithDateRangReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
      "/api/dashboard/departmentdetailsrange",
    action?.payload?.model
  );
  try {
    if (resp) {
      action?.payload?.callback(resp);
      yield put(actionReqResStatusLoaderSagaAction(false));
    }
  } catch (err) {
    action?.payload?.callback("");
    yield put(actionReqResStatusLoaderSagaAction(false));
  }
}

function* getTodayDataReqSaga(action) {
  yield put(actionReqResStatusLoaderSagaAction(true));
  const resp = yield call(
    postAPI,
    process.env.REACT_APP_BASE_URL +
      "/api/dashboard/departmentdetails",
    action?.payload?.model
  );
  try {
    action?.payload?.callback(resp);
    yield put(actionReqResStatusLoaderSagaAction(false));
  } catch (err) {
    yield put(actionReqResStatusLoaderSagaAction(false));
    action?.payload?.callback("");
  }
}

export default function* DepartmentDeatilsWatcherSaga() {
  yield takeEvery(
    ACTION_POST_DEPARTMENT_DETAILS_WITH_DATE_RANGE,
    getDeparmentDetailsWithDateRangReqSaga
  );
  yield takeEvery(ACTION_POST_GET_TODAY_REQ, getTodayDataReqSaga);
}
